.btn-to-top {
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  height: 5.2rem;
  width: 5.2rem;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(4, 29, 65, 0.24);
  z-index: 1000;
  transition: .2s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
}




