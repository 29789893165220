@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Trial';
  src: url('../fonts/DrukTextWide-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
