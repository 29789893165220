//LOGIN USER
.login-page {
  header {
    background: $color-blue;
  }

  .login {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: url("../images/login-bg.jpg") no-repeat 100% 100%;
    background-size: cover;

    &__inner {
      max-width: 84rem;
      width: 100%;
      position: absolute;
      top: 20rem;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6rem 0 7rem;
      background-color: $color-blue;
      border-radius: 0.2rem;

      @include respond(phone) {
        position: static;
        margin-right: 1.6rem;
        margin-top: 20rem;
        padding: 0;
        background-color: transparent;
      }

      .login__form {
        background-color: $color-blue;
        max-width: 40rem;
        width: 100%;

        @include respond(phone) {
          padding: 3rem 1.6rem;
        }

        &__title {
          font-family: $font-default-title;
          font-size: 3.2rem;
          line-height: 4rem;

          @include respond(phone) {
            font-size: 2.4rem;
            text-align: center;
          }
        }

        hr {
          max-width: 12rem;
          margin-top: 1.2rem;
          background-color: $color-white;
          height: 0.1rem;

          @include respond(phone) {
            margin: 1.2rem auto 0;
          }
        }

        .form-group {
          margin-top: 2.4rem;
        }

        .form-btn {
          margin-top: 3rem;
          margin-bottom: 1.2rem;

          a.btn-orange {
            padding: 1.3rem 0;
            width: 100%;
            background-color: $color-yellow;
            text-decoration: none;
            transition: .3s ease;

            &:hover {
              background-color: $gradient;
            }
          }
        }

        .forgot-password-link {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #F8F9FA;
          text-underline-offset: 0.5rem;
          transition: .3s ease-in-out;

          &:hover {
            color: $color-yellow;
            cursor: pointer;
          }
        }

        .sign-up {
          margin-top: 4rem;
          display: flex;
          align-items: center;
          padding-left: 4.7rem;

          @include respond(phone) {
            padding-left: 0;
          }

          &__text {
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #DEE2E6;
          }

          &__link {
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: $color-lightblue;
            text-decoration: none;
            margin-left: 1.6rem;
            transition: .3s ease-in-out;
            position: relative;

            @include respond(phone) {
              font-size: 1.6rem;
            }

            svg {
              transition: .3s ease-in-out;
              position: absolute;
              top: 50%;
              margin-top: -0.4rem;
              right: -2rem;
            }

            &:hover {
              color: $color-yellow;
              cursor: pointer;

              svg {
                stroke: $color-yellow;
                right: -2.5rem;
              }
            }
          }
        }
      }
    }
  }
}

.login__desktop {
  display: flex !important;
  padding-left: 1.6rem !important;
  padding-right: 1.6rem !important;
}
