.about {
  position: relative;
  min-height: 87.6rem;
  overflow: hidden;
  font-family: $font-default;
  min-width: 100vw;

  &__inner {
    .swiper-container {
      min-width: 100vw;

      .swiper-wrapper {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .swiper-slide {
          padding-top: 10rem;

          @include respond(phone) {
            width: 100%;
          }

          .container {
            @include respond(phone) {
              width: 100%;
            }
          }

          .slide-info {
            margin-top: 9.5rem;

            @include respond(phone) {
              min-height: 32.5rem;
              background-color: rgba(4, 30, 65, 0.4);
              border-radius: 0.2rem;
              padding: 2rem 1.4rem;
              position: absolute;
              bottom: 18.4rem;
              width: 90%;

              @include respond(phone) {
                min-height: 0;
              }
            }

            .about__title {
              margin: 1.1rem 0 1.2rem;
              font-family: $font-default-title;
              font-weight: 500;
              font-size: 6.4rem;
              letter-spacing: 0.1rem;
              line-height: 7.2rem;
              color: $color-white;

              @include respond(phone) {
                font-size: 2.4rem;
                line-height: 3.2rem;
              }
            }

            .text-primary {
              @include respond(phone) {
                font-size: 1.4rem;
                line-height: 2.2rem;
              }
            }

            hr {
              background-color: #DEE2E6;
              height: 0.1rem;
              max-width: 49.5rem;

              @include respond(phone) {
                max-width: 23.3rem;
              }

              //IE10+
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: none;
              }
            }

            .about__text {
              margin-top: 2.4rem;
              font-weight: 400;
              font-size: 2rem;
              line-height: 2.8rem;
              color: #DEE2E6;
              max-width: 49.5rem;
            }
          }

          .slide-form-search {
            margin-top: 24.3rem;

            @include respond(tab-land) {
              grid-template-columns: 1fr 16rem;
              grid-column-gap: 2rem;
              .container {
                max-width: 50rem;
              }
            }

            @include respond(phone) {
              bottom: 12rem;
              position: absolute;
              grid-template-columns: 1fr 4.8rem;
              grid-column-gap: 1.2rem;
              right: 1.6rem;
              left: 1.6rem;
              span {
                display: none;
              }
              .form-text {
                display: none;
              }
            }

            .search__wrap {
              position: relative;
              display: flex;
              justify-content: center;

              &::before {
                position: absolute;
                content: '';
                background: url("../images/search-dark.png") no-repeat center center;
                width: 1.8rem;
                height: 1.8rem;
                left: 1.5rem;
                top: 50%;
                margin-top: -0.9rem;

                @include respond(phone) {
                  left: 0.8rem;
                }
              }

              input {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 1.2rem 0 1.2rem 4.6rem;
                background-color: rgba(255, 255, 255, 0.8);
                border: 0.1rem solid #122741;
                border-radius: 0.2rem;
                line-height: 2.4rem;

                @include respond(phone) {
                  font-size: 1.4rem;
                  padding: 1.2rem 0 1.2rem 3.6rem;
                }

                &::placeholder {
                  font-weight: 400;
                  font-size: 1.6rem;
                  color: #565C62;
                  outline: none;

                  @include respond(phone) {
                    font-size: 1.4rem !important;
                    border-radius: 0 !important;
                  }
                }
              }

              .btn-search {
                background-color: $color-lightblue;
                max-width: 20rem;
                width: 100%;
                margin-left: 1.2rem;

                svg {
                  margin-left: 0.9rem;

                  @include respond(phone) {
                    margin-left: 0;
                  }
                }

                @include respond(phone) {
                  margin-left: 0.8rem;
                  width: 5.8rem !important;
                }
              }
            }

            .form-text {
              margin-top: 1.3rem;
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 2.2rem;
              color: #B6B6B6;

              span {
                color: $color-lightblue;
                padding-bottom: 0.5rem;
                border-bottom: 0.1rem solid $color-lightblue;
              }
            }
          }

          &.slide-1 {
            background: url("../images/main-page/about/slide-1.jpg") no-repeat 100% 100%;
            background-size: cover;
          }

          &.slide-2 {
            background: url("../images/main-page/about/slide-2.jpg") no-repeat 100% 100%;
            background-size: cover;
            .about__title {
              max-width: 54.4rem;
            }

            .slide-form-search {
              grid-template-rows: 4.2rem;
              margin-top: 12.4rem;

              input {
                height: 1.6rem;
              }

              button {
                margin-top: 0;
                padding: 0;

                @include respond(phone) {
                  width: 4.8rem !important;
                  padding-left: 0;
                  padding-right: 0;

                  svg {
                    padding: 0;
                    margin: 0;
                  }
                }

                @media (min-width: 180rem) {
                  height: 2.4rem;
                }
              }
            }

            button {
              margin-top: 3.2rem;
              padding: 1rem;
              max-width: 16.5rem;
              width: 100%;
              background-color: $color-yellow;

              &:hover {
                background-color: $gradient;
              }
            }
          }
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-next,
      .swiper-button-prev {
        margin-top: -12rem;
        border: none;
        outline: none;

        @include respond(phone) {
          margin-top: -15rem;
        }
      }

      .swiper-button-prev {
        margin-left: 9.5rem;
        background: url("../images/main-page/about/arrow-back.png") no-repeat;

        @include respond(phone) {
          margin-top: -13rem;
        }

        @media (max-width: 150rem) {
          margin-left: 4rem;
        }
        @media (max-width: 140rem) {
          margin-left: 1.5rem;
        }
        @media (min-width: 76.7rem) and (max-width: 100rem) {
          margin-top: -1rem;
        }
      }

      .btn__swiper__wrap {
        display: flex;
        align-items: center;
      }

      .swiper-button-next {
        background: url("../images/main-page/about/arrow-next.png") no-repeat;
        margin-right: 9.5rem;
        //margin-top: -11rem;

        @media (max-width: 150rem) {
          margin-right: 4rem;
        }
        @media (max-width: 140rem) {
          margin-right: 2.5rem;
        }

        @include respond(phone) {
          margin-top: -13rem;
        }
        @media (min-width: 76.7rem) and (max-width: 100rem) {
          margin-top: -1rem;
        }
      }

      .swiper-pagination {
        margin-bottom: 8.5rem;

        .swiper-pagination-bullet {
          height: 0.2rem;
          width: 13.1rem;
          border-radius: 0;
          background: rgba(255, 255, 255, 0.28);

          @include respond(phone) {
            display: none;
          }

          &.swiper-pagination-bullet-active {
            background-color: $color-white;
          }
        }
      }
    }
  }

  @include respond(tab-land) {
    height: calc(100vh - 9.2rem);
  }
}
