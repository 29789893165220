@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Media Query
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 47.9375em) {
            @content;
        } // 767px
    }

    @if $breakpoint == tab-land {
        @media only screen and (max-width: 80em) {
            @content;
        } // to 1280px
    }

    @if $breakpoint == monitor {
        @media only screen and (min-width: 80.0625em) {
            @content;
        } // from 1281px
    }

    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) {
            @content;
        } //1800px
    }
}
