.form-group__input {
  display: inline-block;
  width: 100%;
  background: #F8F9FA;
  border: 0.1rem solid #122741;
  border-radius: 0.2rem;
  outline: none;
  padding: 1.4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color-blue;

  &::placeholder {
    font-family: $font-default;
    color: #B6B6B6;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
