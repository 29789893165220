.subtopics {
  background-color: $color-white;
  padding: 8rem 0 2rem;
  font-family: $font-default;

  &__control {
    margin-bottom: 3rem;

    .swiper-container {
      background-color: #F8F9FA;
      border-radius: 0.2rem;
      padding: 0.8rem 0;
      overflow: hidden;

      .swiper-wrapper {
        position: relative;

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 4.5rem;
          border-right: 0.1rem solid #DADADA;
          padding-right: 1.6rem;
          padding-left: 1.6rem;

          //IE10+
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            max-width: 20rem;
            width: 100%;
          }

          @include respond(phone) {
            display: flex;
            justify-content: center;
          }

          .subtopics__filter__link {
            padding: 1.1rem 0;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            text-decoration: none;
            font-size: 1.2rem;
            line-height: 2.2rem;
            color: #565C62;
            border-radius: 0.2rem;
            cursor: pointer;
            outline: none;
            border: none;
            background: transparent;
            transition: .3s linear;

            &.active {
              background: $color-blue;
              color: $color-white;
            }
          }
        }
      }
    }

    .btn-wrap {
      position: relative;
      height: 0;
      background-color: silver;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .swiper-button-prev,
      .swiper-button-next {
        position: relative;
        border: none;
        outline: none;
        top: -2rem;

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: none;
        }

        @include respond(tab-land) {
         display: none;
        }

        &::after {
          display: none;
        }
      }

      .swiper-button-prev {
        left: -5rem;
      }

      .swiper-button-next {
        left: 5rem;
      }
    }
  }

  &__info {
    max-width: 59rem;
    padding-left: 3rem;
    border-left: 0.1rem solid $color-blue;
    margin-bottom: 6rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    margin-bottom: 6rem;

    &__item {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $color-white;
      border: 0.12rem solid $color-blue;
      position: relative;
      transition: .3s ease-in-out;
      cursor: pointer;

      &:hover {
        .subtopics__list__item__wrap {
          background-color: $color-blue;

          .subtopics__list__item__name,
          .subtopics__list__item__text {
            color: $color-white;
            transition: .3s ease-in-out;
          }
        }
      }

      &:first-child {
        @include respond(phone) {
          min-height: 18.9rem;
        }
      }

      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 0;
        border-bottom: 0;
        padding-right: 4rem;

        @include respond(phone) {
          border-left: 0;
          border-top: 0;
          border-right: 0.12rem solid $color-blue;
          border-bottom: 0.12rem solid $color-blue;
        }

        .subtopics__list__item__name {
          font-family: $font-default-title;
          color: $color-lightblue;
          font-size: 2rem;
          line-height: 2.8rem;
          align-self: flex-start;
        }

        hr {
          margin: 1rem 0;
          max-width: 14rem;
          width: 100%;
          height: 0.1rem;
          background-color: $color-lightblue;
          align-self: flex-start;
        }

        .subtopics__list__item__text {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #565C62;
          margin: 0;

          //IE10+
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
           max-width: 27.5rem;
          }
        }
      }

      .subtopics__list__item__image {
        width: 100%;
      }

      .subtopics__list__item__wrap {
        padding: 1.6rem;
        position: absolute;
        max-height: 16.9rem;
        height: 100%;
        bottom: 0;
        left: 0;
        max-width: 30.2rem;
        width: 100%;
        background-color: $color-white;

        .subtopics__list__item__name {
          font-size: 2rem;
          line-height: 2.8rem;
          color: $color-blue;
          margin-bottom: 0.8rem;
        }

        hr {
          width: 12rem;
          height: 0.1rem;
          background-color: $color-lightblue;
        }

        .subtopics__list__item__text {
          margin-top: 1.2rem;
          text-align: left;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #565C62;
        }
      }

      hr {
        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          text-align: left;
        }
      }

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 36rem;
        width: 100%;
        margin-bottom: 4rem;
      }
    }

    //IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @include respond(tab-land) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include respond(phone) {
    padding: 4rem 0 6rem;

    &__info {
      padding-left: 1.6rem;
      border-left-color: $color-blue;

      &__title {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }

      &__text {
        font-size: 3.2rem;
        line-height: 4rem;
      }
    }

    &__list {
      grid-gap: 3rem;
    }
  }
}

