.btn-primary {
  background-color: $color-lightblue;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color-white;
  cursor: pointer;
  transition: .3s ease-in;

  svg {
    transition: .3s ease-in;
  }

  &:hover   {
    svg {
      margin-left: 2rem !important;
    }
  }
}

.btn-orange  {
  background-color: $color-yellow;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color-white;
  cursor: pointer;
  transition: .3s ease-in;

  svg {
    transition: .3s ease-in;
  }

  &:hover   {
    svg {
      margin-left: 2rem !important;
    }
  }
}
