.premiere-speakers {
  background: url("../images/main-page/premiere-speakers/premiere-speakers-bg.jpg") no-repeat 100% 100%;
  background-size: cover;
  font-family: $font-default;
  padding: 10rem 0 27.7rem;

  &__info {
    border-left: 0.1rem solid #DEE2E6;
    padding-left: 3rem;

    .text-title {
      font-size: 6.4rem;
      line-height: 7.2rem;
      font-family: $font-default-title;
      font-weight: 500;
      color: $color-white;
    }

    .premiere-speakers__text {
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.8rem;
      color: #F0EFEF;
      max-width: 51.3rem;
      margin-top: 2rem;
    }
  }

  .btn-orange {
    margin-left: 3rem;
    max-width: 22rem;
    padding: 1.2rem 0;
    width: 100%;
    margin-top: 4rem;
  }

  @include respond(phone) {
    padding: 4rem 0 26.9rem;

    .container {
      margin: 0;

      .premiere-speakers__info {
        padding-left: 1.6rem;

        &__title {
          margin-bottom: 0.8rem;
          font-size: 1.4rem;
          line-height: 2.2rem;
        }

        .text-title {
          font-size: 3.2rem;
          line-height: 4rem;
        }
      }

      .btn-orange {
        margin-left: 1.6rem;
      }
    }
  }
}
