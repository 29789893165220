.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  background: rgba(18, 39, 65, 0.56);
  opacity: 0;
  visibility: hidden;
  transition: .4s linear;
  color: $color-blue;
  z-index: 1000;

  &__body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal__content {
      display: flex;
      padding: 2rem;
      background-color: $color-white;
      max-width: 116rem;
      width: 100%;
      position: relative;
      min-height: 55rem;

      @include respond(phone) {
        margin-right: 1.6rem;
        margin-left: 1.6rem;
        padding: 6.4rem 0.8rem 2rem 0.8rem;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 2rem);
        overflow-y: scroll;
      }

      .modal__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .3s;
        overflow: hidden;
        outline: none;
        background: transparent;
        border: 0.1rem solid $color-blue;
        z-index: 10;
      }

      .modal__image {
        max-width: 39rem;
        width: 100%;
        height: 100%;

        @include respond(phone) {
          max-width: 100%;
        }

        .modal__image__inner {
          padding: 5.9rem 0 0 5.9rem;
          border-left: 0.1rem solid $color-blue;
          border-top: 0.1rem solid $color-blue;
          position: relative;

          @include respond(phone) {
            border-top: none;
            padding: 1.9rem 1.9rem 0 1.9rem;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              display: block;
              width: 50%;
              height: 0.1rem;
              background: $color-blue;
              top: 0;
              left: 0;
            }
          }

          img {
            display: block;
            max-height: 44rem;
            max-width: 33rem;
            width: 100%;
            height: 100%;
            font-size: 0;
            line-height: 0;
            margin-bottom: 7rem;

            @include respond(phone) {
              margin-bottom: 0;
            }
          }

          .line {
            top: 0.6rem;
            left: 0.6rem;
            position: absolute;
            width: 4.5rem;
            height: 4.5rem;
            border-left: 0.4rem solid $color-blue;
            border-top: 0.4rem solid $color-blue;

            @include respond(phone) {
              width: 3.5rem;
              height: 3.5rem;
            }
          }
        }
      }

      .modal__info {
        display: flex;
        align-items: center;
        margin-left: 4rem;
        width: 100%;
        position: relative;

        @include respond(phone) {
          margin-left: 1.9rem;
          padding-bottom: 3rem;
          width: auto;
        }


        &::before, &::after {
          position: absolute;
          content: '';
          display: block;
          background: $color-blue;
          bottom: 0;
          right: 0;
        }

        &::before {
          max-width: 50rem;
          width: 100%;
          height: 0.1rem;

          @include respond(phone) {
            width: 50%;
          }
        }

        &::after {
          max-width: 50rem;
          width: 0.1rem;
          max-height: 38.9rem;
          height: 100%;
        }

        .line {
          bottom: 0.6rem;
          right: 0.6rem;
          position: absolute;
          width: 4.5rem;
          height: 4.5rem;
          border-bottom: 0.4rem solid $color-blue;
          border-right: 0.4rem solid $color-blue;

          @include respond(phone) {
            width: 3.5rem;
            height: 3.5rem;
          }
        }

        &__inner {
          max-width: 45rem;

          @include respond(phone) {
            margin-top: 3rem;
            padding-right: 3rem;
          }

          &__title {
            font-family: $font-default-title;
            font-weight: 500;
            font-size: 3.2rem;
            line-height: 4rem;
            color: #222222;

            @include respond(phone) {
              font-size: 2.4rem;
              line-height: 3.2rem;
            }
          }

          hr {
            margin: 1.2rem 0;
            background: $color-lightblue;
            height: 0.1rem;
            max-width: 12.6rem;

            @include respond(phone) {
              margin: 0.8rem 0 !important;
            }
          }

          &__position {
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #565C62;
          }

          &__contact__wrap {
            margin: 3.4rem 0 3rem;
            display: grid;
            grid-template-columns: 3.4rem 1fr;
            grid-gap: 2rem 1.6rem;

            @include respond(phone) {
              margin: 2.4rem 0 2rem;
              grid-gap: 1.6rem 0.6rem;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3.4rem;
              height: 3.4rem;
              position: relative;
              background: $color-blue;

              &::before {
                display: block;
                content: '';
                position: absolute;
                top: -0.4rem;
                left: -0.4rem;
                border: 0.1rem solid $color-blue;
                width: 3.4rem;
                height: 3.4rem;
              }
            }
          }

          &__text {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #565C62;
          }
        }
      }
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }
}
