@font-face {
  font-family: 'Gotham Pro';
  src: url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro';
  src: url("../fonts/GothamPro.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro';
  src: url("../fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Druk Wide Trial';
  src: url("../fonts/DrukTextWide-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden; }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

html.block-scroll {
  overflow: hidden; }

body {
  display: flex;
  flex-direction: column;
  font-family: "Gotham Pro", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  color: #ffffff;
  background-color: #041E41;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden; }
  body.no-scroll {
    overflow: hidden; }

.container {
  max-width: 116rem;
  width: 100%;
  margin: 0 auto; }
  @media only screen and (max-width: 80em) {
    .container {
      max-width: 83.4rem;
      padding: 0 2rem; } }
  @media only screen and (max-width: 47.9375em) {
    .container {
      padding: 0 1.6rem; } }

hr {
  border: none;
  outline: none;
  display: block;
  background-color: #009BDE; }

select,
textarea,
input[type="text"],
input[type="search"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

button,
input,
textarea,
select {
  font-family: 'Syne', sans-serif;
  outline: none; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

.text-title {
  font-family: "Druk Wide Trial", sans-serif;
  font-weight: 500;
  font-size: 4rem;
  line-height: 4.8rem;
  color: #041E41; }
  @media only screen and (max-width: 47.9375em) {
    .text-title {
      font-size: 2.8rem;
      line-height: 3.6rem; } }

.text-primary {
  font-family: "Gotham Pro", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  text-transform: uppercase;
  color: #009BDE; }
  @media only screen and (max-width: 47.9375em) {
    .text-primary {
      font-size: 1.4rem;
      line-height: 2.2rem; } }

.form-group__input {
  display: inline-block;
  width: 100%;
  background: #F8F9FA;
  border: 0.1rem solid #122741;
  border-radius: 0.2rem;
  outline: none;
  padding: 1.4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #041E41; }
  .form-group__input::placeholder {
    font-family: "Gotham Pro", sans-serif;
    color: #B6B6B6;
    font-size: 1.6rem;
    line-height: 2.4rem; }

.custom-checkbox {
  width: 2rem;
  height: 2rem;
  border: 0.1rem solid #009BDE;
  border-radius: 0.1rem;
  background: transparent;
  vertical-align: sub;
  position: relative; }
  .custom-checkbox::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: url("../images/shape.svg") no-repeat;
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    margin-top: 0.2rem;
    transition: 0.2s ease-in; }

.checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute; }

.checkbox__input:checked + span.custom-checkbox::before {
  transform: translate(-50%, -50%) scale(1);
  border: none; }

.checkbox__input:checked + span.custom-checkbox {
  background: #009BDE; }

.btn-primary {
  background-color: #009BDE;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
  cursor: pointer;
  transition: .3s ease-in; }
  .btn-primary svg {
    transition: .3s ease-in; }
  .btn-primary:hover svg {
    margin-left: 2rem !important; }

.btn-orange {
  background-color: #FFBD21;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
  cursor: pointer;
  transition: .3s ease-in; }
  .btn-orange svg {
    transition: .3s ease-in; }
  .btn-orange:hover svg {
    margin-left: 2rem !important; }

.header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
  z-index: 1000;
  max-height: 9.6rem; }
  .header.dark {
    background-color: #041E41;
    z-index: 100; }
    .header.dark + .about {
      position: relative; }
      .header.dark + .about::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(18, 39, 65, 0.56);
        z-index: 5; }
  .header .container {
    padding: 0; }
  .header__inner .desktop {
    display: flex;
    align-items: center;
    padding: 2.8rem 0;
    justify-content: space-between; }
    @media only screen and (max-width: 80em) {
      .header__inner .desktop {
        padding: 2.5rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .header__inner .desktop {
        padding: 2.9rem 0;
        display: none; } }
    .header__inner .desktop .header__logo {
      line-height: 0; }
  .header__inner .mobile {
    display: none;
    background-color: #122741;
    height: 100%; }
    .header__inner .mobile__top {
      padding: 1.7rem 1.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #041E41; }
      .header__inner .mobile__top .logo {
        max-width: 12.6rem;
        max-height: 3rem; }
      .header__inner .mobile__top .hamburger__mobile {
        width: 1.6rem;
        height: 1.6rem;
        background-color: transparent;
        border: none;
        outline: none; }
        .header__inner .mobile__top .hamburger__mobile svg.close {
          display: none; }
        .header__inner .mobile__top .hamburger__mobile.active svg.active {
          display: none; }
        .header__inner .mobile__top .hamburger__mobile.active svg.close {
          display: flex; }
    .header__inner .mobile .search__mobile,
    .header__inner .mobile .search__results,
    .header__inner .mobile .menu__dropdown__mobile {
      display: none; }
    .header__inner .mobile .search__results {
      display: none; }
      .header__inner .mobile .search__results__title {
        font-size: 1.4rem;
        line-height: 2.2rem; }
    @media only screen and (max-width: 47.9375em) {
      .header__inner .mobile {
        display: flex;
        flex-direction: column; } }
    .header__inner .mobile.active {
      height: 100vh;
      overflow-y: scroll; }
      .header__inner .mobile.active .search__mobile {
        display: flex;
        flex-direction: column;
        margin: 4rem 1.6rem 0;
        background-color: #041E41;
        color: #ffffff; }
        .header__inner .mobile.active .search__mobile .search__wrap {
          padding: 0 1.2rem;
          display: flex;
          align-items: center; }
        .header__inner .mobile.active .search__mobile .search__mobile__input {
          background-color: transparent;
          padding: 1.2rem;
          border: none;
          outline: none;
          color: #ffffff;
          width: 100%; }
      .header__inner .mobile.active .search__results {
        display: flex;
        flex-direction: column;
        padding: 1.2rem 1.2rem 1.7rem;
        margin: 0.4rem 1.6rem 0;
        background-color: #041E41;
        position: absolute;
        opacity: 0;
        visibility: hidden; }
        .header__inner .mobile.active .search__results__label {
          margin-top: 1.2rem;
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #B6B6B6; }
          .header__inner .mobile.active .search__results__label span.search__results__text {
            margin-left: 1rem; }
        .header__inner .mobile.active .search__results.active {
          position: static;
          opacity: 1;
          visibility: visible; }
      .header__inner .mobile.active .menu__dropdown__mobile {
        margin: 4rem 1.6rem 0;
        display: flex;
        flex-direction: column;
        transition: .3s linear; }
        .header__inner .mobile.active .menu__dropdown__mobile__wrap {
          display: flex;
          flex-direction: column;
          transition: 1s ease-in-out;
          margin-bottom: 5rem;
          position: relative; }
          .header__inner .mobile.active .menu__dropdown__mobile__wrap .menu__dropdown__mobile__title {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .header__inner .mobile.active .menu__dropdown__mobile__wrap .menu__dropdown__mobile__title svg {
              transition: .1s linear; }
            .header__inner .mobile.active .menu__dropdown__mobile__wrap .menu__dropdown__mobile__title:last-child {
              background-color: #122741; }
          .header__inner .mobile.active .menu__dropdown__mobile__wrap .login {
            display: inline-block;
            text-decoration: underline;
            text-underline-offset: 0.3rem; }
            .header__inner .mobile.active .menu__dropdown__mobile__wrap .login::after {
              display: none; }
          .header__inner .mobile.active .menu__dropdown__mobile__wrap .menu__dropdown__mobile__link {
            display: none;
            text-decoration: none;
            color: #ffffff; }
        .header__inner .mobile.active .menu__dropdown__mobile .menu__dropdown__mobile__title.active svg {
          transform: rotate(90deg);
          right: 0.01rem;
          top: 0.5rem; }
        .header__inner .mobile.active .menu__dropdown__mobile .menu__dropdown__mobile__title.active ~ .menu__dropdown__mobile__link {
          display: inline-block;
          margin-top: 2rem; }
          .header__inner .mobile.active .menu__dropdown__mobile .menu__dropdown__mobile__title.active ~ .menu__dropdown__mobile__link:hover {
            color: #009BDE; }
  .header__navigation {
    display: flex;
    margin-left: auto;
    margin-right: 3.2rem; }
    @media only screen and (max-width: 47.9375em) {
      .header__navigation {
        display: none; } }
    .header__navigation__item {
      display: flex;
      color: #ffffff;
      margin-right: 7.5rem;
      position: relative; }
      .header__navigation__item:last-child {
        margin-right: 0; }
      .header__navigation__item.active .header__navigation__item__link {
        background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        -webkit-background-clip: text;
        letter-spacing: 0.1rem;
        color: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%); }
        .header__navigation__item.active .header__navigation__item__link:hover {
          color: #009BDE; }
        .header__navigation__item.active .header__navigation__item__link:first-child {
          display: flex;
          align-items: center; }
          .header__navigation__item.active .header__navigation__item__link:first-child span:last-child {
            margin-left: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.4rem;
            width: 2.4rem;
            background: rgba(255, 255, 255, 0.08);
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #DEE2E6;
            border-radius: 50%; }
      .header__navigation__item__link {
        font-size: 1.4rem;
        font-family: "Gotham Pro", sans-serif;
        line-height: 2.1rem;
        font-weight: 400;
        text-decoration: none;
        transition: all 0.3s ease;
        color: #ffffff; }
        .header__navigation__item__link:hover {
          color: #009BDE; }
          .header__navigation__item__link:hover svg * {
            fill: #009BDE; }
        .header__navigation__item__link.select svg {
          left: 0.4rem;
          top: -0.2rem;
          position: relative; }
        .header__navigation__item__link.active {
          color: #009BDE; }
          .header__navigation__item__link.active svg * {
            fill: #009BDE; }
          .header__navigation__item__link.active svg {
            transition: .3s ease;
            transform: rotateX(180deg); }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .header__navigation__item__link:first-child {
            background: transparent !important; } }
      .header__navigation__item .select__list {
        display: flex;
        transition: .3s linear;
        justify-content: space-between;
        padding: 4rem 7rem;
        position: absolute;
        width: 73.9rem;
        left: -40rem;
        background-color: #041E41;
        top: -100rem; }
        .header__navigation__item .select__list__column {
          max-width: 12rem;
          display: flex;
          flex-direction: column; }
          .header__navigation__item .select__list__column .select__list__link {
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #F0EFEF;
            margin-bottom: 1.6rem; }
            .header__navigation__item .select__list__column .select__list__link:hover {
              cursor: pointer;
              color: #009BDE;
              transition: .2s ease-in-out;
              text-decoration: underline;
              text-underline-offset: 0.3rem; }
            .header__navigation__item .select__list__column .select__list__link:last-child {
              margin-bottom: 0; }
        .header__navigation__item .select__list.active {
          top: 5.8rem; }
          @media only screen and (min-width: 112.5em) {
            .header__navigation__item .select__list.active {
              top: 5.5rem; } }
          @media only screen and (max-width: 80em) {
            .header__navigation__item .select__list.active {
              top: 5.5rem; } }
    .header__navigation.disable {
      display: none; }
  .header .search-active {
    margin-left: 9.8rem;
    width: 100%;
    display: none;
    align-items: center;
    position: relative;
    opacity: 0;
    transition: 3s cubic-bezier(0.39, 1.52, 0.46, 0.92);
    color: #ffffff; }
    .header .search-active::before {
      position: absolute;
      content: '';
      background: url("../images/search.png") no-repeat center center;
      width: 1.8rem;
      height: 1.8rem;
      left: 1.5rem;
      top: 50%;
      margin-top: -0.9rem; }
    @media (max-width: 128rem) {
      .header .search-active {
        margin-left: 0;
        width: 50%; } }
    .header .search-active-input {
      background-color: rgba(255, 255, 255, 0.08);
      border-radius: 0.2rem;
      color: #B6B6B6;
      max-width: 54.8rem;
      width: 100%;
      padding: 1.2rem;
      border: none;
      outline: none;
      text-indent: 3.8rem; }
      .header .search-active-input::placeholder {
        color: #B6B6B6; }
    .header .search-active-button {
      margin-left: 1.2rem;
      padding: 0.9rem;
      max-width: 16rem;
      width: 100%; }
    .header .search-active .popular-searches {
      position: absolute;
      top: 6rem;
      padding: 4rem 6rem;
      background-color: #041E41;
      color: #ffffff; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .header .search-active .popular-searches {
          width: 100%;
          left: 0; } }
      @media only screen and (max-width: 80em) {
        .header .search-active .popular-searches {
          padding: 4rem 1rem; } }
      .header .search-active .popular-searches-title {
        font-family: "Druk Wide Trial", sans-serif;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.8rem;
        text-align: center; }
      .header .search-active .popular-searches hr {
        max-width: 26.5rem;
        width: 100%;
        margin: 1.2rem auto;
        height: 0.1rem; }
      .header .search-active .popular-searches-text {
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        color: #DEE2E6; }
      .header .search-active .popular-searches__list {
        margin-top: 4rem;
        display: grid;
        grid-template-columns: repeat(4, minmax(5rem, 12rem));
        grid-column-gap: 4rem; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .header .search-active .popular-searches__list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; } }
        @media only screen and (max-width: 80em) {
          .header .search-active .popular-searches__list {
            grid-column-gap: 2rem; } }
        .header .search-active .popular-searches__list .popular-searches__column {
          display: flex;
          flex-direction: column; }
          .header .search-active .popular-searches__list .popular-searches__column .popular-searches__list__link {
            font-size: 1.4rem;
            line-height: 2.2rem;
            transition: .3s ease;
            text-decoration: none;
            cursor: pointer;
            color: #ffffff;
            text-underline-offset: 0.3rem;
            margin-bottom: 1.6rem; }
            .header .search-active .popular-searches__list .popular-searches__column .popular-searches__list__link:hover {
              color: #009BDE;
              text-decoration: underline; }
    .header .search-active.active {
      display: flex;
      opacity: 1; }
      @media only screen and (max-width: 80em) {
        .header .search-active.active {
          max-width: 50rem;
          width: 100%; } }
  .header__menu {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 47.9375em) {
      .header__menu {
        position: absolute;
        right: 2.4rem; } }
    .header__menu__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.4rem;
      height: 3.4rem;
      background-color: rgba(255, 255, 255, 0.08);
      border-radius: 0.2rem;
      border: none;
      outline: none;
      cursor: pointer;
      position: relative; }
      .header__menu__btn:hover {
        transition: .3s ease-in-out;
        background: #009BDE; }
      @media only screen and (max-width: 47.9375em) {
        .header__menu__btn {
          background-color: transparent; } }
    .header__menu .header__menu__btn.search {
      position: relative; }
      .header__menu .header__menu__btn.search::after {
        content: '';
        position: absolute;
        background-color: rgba(255, 255, 255, 0.29);
        border-radius: 0.1rem;
        width: 0.1rem;
        height: 2.4rem;
        right: -1.25rem;
        top: 50%;
        margin-top: -1.2rem; }
        @media only screen and (max-width: 47.9375em) {
          .header__menu .header__menu__btn.search::after {
            display: none; } }
      @media only screen and (max-width: 47.9375em) {
        .header__menu .header__menu__btn.search {
          display: none; } }
    .header__menu .hamburger {
      margin-left: 2.5rem; }
      @media only screen and (max-width: 47.9375em) {
        .header__menu .hamburger {
          margin-left: 0; } }
      .header__menu .hamburger svg.close {
        display: none; }
      .header__menu .hamburger.active svg.active {
        display: none; }
      .header__menu .hamburger.active svg.close {
        display: flex; }
      .header__menu .hamburger.active:hover {
        background: #F0EFEF; }
        .header__menu .hamburger.active:hover svg.close * {
          fill: #009BDE; }
  .header .menu__dropdown {
    transition: .5s linear;
    display: flex;
    flex-direction: column;
    background-color: #122741;
    color: #ffffff;
    position: absolute;
    max-width: 78rem;
    width: 100%;
    height: calc(100vh - 9rem);
    padding: 4rem 6rem;
    right: -100rem;
    visibility: hidden;
    top: 9.6rem;
    z-index: 100;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (min-width: 112.5em) {
      .header .menu__dropdown {
        top: 9rem; } }
    @media only screen and (max-width: 80em) {
      .header .menu__dropdown {
        top: 9rem; } }
    @media only screen and (max-width: 47.9375em) {
      .header .menu__dropdown {
        display: none !important; } }
    .header .menu__dropdown__title {
      margin-bottom: 3.7rem;
      margin-top: 6rem;
      font-size: 2rem;
      line-height: 2.8rem; }
      .header .menu__dropdown__title:first-child {
        margin-top: 0; }
    .header .menu__dropdown__wrap {
      display: grid;
      grid-template-columns: repeat(2, minmax(8rem, 16rem));
      grid-column-gap: 6rem; }
      .header .menu__dropdown__wrap.company {
        display: flex;
        flex-direction: column; }
      .header .menu__dropdown__wrap.address {
        display: flex;
        flex-direction: column; }
        .header .menu__dropdown__wrap.address .number {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: flex-end;
          text-decoration: none;
          color: #DEE2E6; }
          .header .menu__dropdown__wrap.address .number:hover {
            color: #009BDE; }
            .header .menu__dropdown__wrap.address .number:hover svg * {
              fill: #009BDE; }
          .header .menu__dropdown__wrap.address .number svg {
            margin-right: 1.2rem; }
      .header .menu__dropdown__wrap .menu__dropdown__link {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #F0EFEF;
        margin-bottom: 1.6rem;
        transition: .3s ease;
        text-decoration: none;
        cursor: pointer; }
        .header .menu__dropdown__wrap .menu__dropdown__link:hover {
          color: #009BDE; }
    .header .menu__dropdown__wrap:nth-child(3) {
      display: flex;
      flex-direction: column; }
    .header .menu__dropdown.active {
      visibility: visible;
      right: 0; }
  .header .popup-background {
    position: fixed;
    top: 9.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(18, 39, 65, 0.56);
    height: calc(100vh - 9rem);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease; }
    .header .popup-background.active {
      opacity: 1;
      visibility: visible; }

.footer {
  color: #ffffff; }
  .footer__top {
    background-color: #122741; }
    .footer__top .container {
      display: flex;
      justify-content: space-between;
      padding: 2.4rem 0; }
      @media only screen and (max-width: 47.9375em) {
        .footer__top .container {
          padding: 1.6rem; } }
      .footer__top .container .footer__logo {
        cursor: pointer; }
      .footer__top .container .footer__social, .footer__top .container .footer__mobile__social {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 47.9375em) {
          .footer__top .container .footer__social, .footer__top .container .footer__mobile__social {
            display: none; } }
        .footer__top .container .footer__social__link, .footer__top .container .footer__mobile__social__link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.8rem;
          height: 3.8rem;
          cursor: pointer;
          background-color: #DEE2E6;
          border-radius: 0.2rem;
          transition: .3s ease;
          border: 0.1rem solid #DEE2E6; }
          .footer__top .container .footer__social__link:nth-child(2), .footer__top .container .footer__mobile__social__link:nth-child(2) {
            margin: 0 0.8rem; }
          .footer__top .container .footer__social__link:hover, .footer__top .container .footer__mobile__social__link:hover {
            background-color: transparent;
            border: 0.1rem solid #009BDE; }
            .footer__top .container .footer__social__link:hover svg *, .footer__top .container .footer__mobile__social__link:hover svg * {
              transition: .3s ease;
              fill: #009BDE; }
  .footer__center {
    padding: 4rem 0; }
    .footer__center__inner {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 47.9375em) {
        .footer__center__inner {
          flex-direction: column; } }
      .footer__center__inner .footer__contact {
        display: flex;
        flex-direction: column; }
        @media only screen and (max-width: 47.9375em) {
          .footer__center__inner .footer__contact {
            border-bottom: 0.1rem solid #565C62;
            padding-bottom: 3.2rem;
            padding-top: 3.2rem; }
            .footer__center__inner .footer__contact:first-child {
              padding-top: 0; }
            .footer__center__inner .footer__contact:last-child {
              border-bottom: none;
              padding-bottom: 0; } }
        .footer__center__inner .footer__contact__title {
          font-size: 1.6rem;
          line-height: 2.4rem;
          text-transform: uppercase; }
        .footer__center__inner .footer__contact__link {
          margin-top: 1.2rem;
          text-decoration: none;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #F0EFEF;
          transition: .3s ease-in-out; }
          .footer__center__inner .footer__contact__link:hover {
            color: #009BDE;
            cursor: pointer; }
            .footer__center__inner .footer__contact__link:hover svg * {
              transition: .3s ease-in-out;
              fill: #009BDE; }
        .footer__center__inner .footer__contact .footer__contact__link__number {
          display: flex;
          align-items: center; }
          .footer__center__inner .footer__contact .footer__contact__link__number svg {
            margin-right: 0.8rem; }
  .footer__bottom__inner {
    padding: 3.6rem 0;
    border-top: 0.1rem solid rgba(248, 249, 250, 0.2);
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 47.9375em) {
      .footer__bottom__inner {
        flex-direction: column-reverse;
        align-items: center;
        border-top: none; } }
    .footer__bottom__inner .copy {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: #DEE2E6;
      width: 50%; }
      @media only screen and (max-width: 47.9375em) {
        .footer__bottom__inner .copy {
          margin-top: 2rem;
          width: 100%;
          justify-content: center; } }
    .footer__bottom__inner .private {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%; }
      @media only screen and (max-width: 47.9375em) {
        .footer__bottom__inner .private {
          border-bottom: 0.1rem solid rgba(248, 249, 250, 0.2);
          justify-content: center; } }
      .footer__bottom__inner .private .private__link {
        padding: 1.2rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #009BDE;
        text-decoration: none;
        position: relative;
        transition: .3s ease-in-out; }
        @media only screen and (max-width: 47.9375em) {
          .footer__bottom__inner .private .private__link {
            font-size: 1.4rem;
            line-height: 2.2rem;
            padding: 1.2rem 0.2rem; } }
        @media (max-width: 37rem) {
          .footer__bottom__inner .private .private__link {
            text-align: center; } }
        .footer__bottom__inner .private .private__link:hover {
          opacity: 0.6; }
        .footer__bottom__inner .private .private__link::after {
          content: '';
          position: absolute;
          width: 0.1rem;
          height: 1.8rem;
          background-color: #565C62;
          right: -0.6rem;
          top: 50%;
          margin-top: -0.9rem; }
        .footer__bottom__inner .private .private__link:nth-child(2) {
          margin: 0 1.2rem; }
          @media only screen and (max-width: 47.9375em) {
            .footer__bottom__inner .private .private__link:nth-child(2) {
              margin: 0 1rem; } }
        .footer__bottom__inner .private .private__link:last-child::after {
          display: none; }
  .footer__mobile__social {
    display: none;
    justify-content: center; }
    .footer__mobile__social .footer__social__link {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #DEE2E6;
      border-radius: 0.2rem;
      width: 3.8rem;
      height: 3.8rem; }
      .footer__mobile__social .footer__social__link:nth-child(2) {
        margin: 0 0.8rem; }
    @media only screen and (max-width: 47.9375em) {
      .footer__mobile__social {
        display: flex; } }

.btn-to-top {
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  height: 5.2rem;
  width: 5.2rem;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(4, 29, 65, 0.24);
  z-index: 1000;
  transition: .2s ease-in-out; }
  .btn-to-top:hover {
    transform: scale(0.9); }

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  background: rgba(18, 39, 65, 0.56);
  opacity: 0;
  visibility: hidden;
  transition: .4s linear;
  color: #041E41;
  z-index: 1000; }
  .modal__body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modal__body .modal__content {
      display: flex;
      padding: 2rem;
      background-color: #ffffff;
      max-width: 116rem;
      width: 100%;
      position: relative;
      min-height: 55rem; }
      @media only screen and (max-width: 47.9375em) {
        .modal__body .modal__content {
          margin-right: 1.6rem;
          margin-left: 1.6rem;
          padding: 6.4rem 0.8rem 2rem 0.8rem;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100vh - 2rem);
          overflow-y: scroll; } }
      .modal__body .modal__content .modal__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .3s;
        overflow: hidden;
        outline: none;
        background: transparent;
        border: 0.1rem solid #041E41;
        z-index: 10; }
      .modal__body .modal__content .modal__image {
        max-width: 39rem;
        width: 100%;
        height: 100%; }
        @media only screen and (max-width: 47.9375em) {
          .modal__body .modal__content .modal__image {
            max-width: 100%; } }
        .modal__body .modal__content .modal__image .modal__image__inner {
          padding: 5.9rem 0 0 5.9rem;
          border-left: 0.1rem solid #041E41;
          border-top: 0.1rem solid #041E41;
          position: relative; }
          @media only screen and (max-width: 47.9375em) {
            .modal__body .modal__content .modal__image .modal__image__inner {
              border-top: none;
              padding: 1.9rem 1.9rem 0 1.9rem;
              position: relative; }
              .modal__body .modal__content .modal__image .modal__image__inner::after {
                content: '';
                position: absolute;
                display: block;
                width: 50%;
                height: 0.1rem;
                background: #041E41;
                top: 0;
                left: 0; } }
          .modal__body .modal__content .modal__image .modal__image__inner img {
            display: block;
            max-height: 44rem;
            max-width: 33rem;
            width: 100%;
            height: 100%;
            font-size: 0;
            line-height: 0;
            margin-bottom: 7rem; }
            @media only screen and (max-width: 47.9375em) {
              .modal__body .modal__content .modal__image .modal__image__inner img {
                margin-bottom: 0; } }
          .modal__body .modal__content .modal__image .modal__image__inner .line {
            top: 0.6rem;
            left: 0.6rem;
            position: absolute;
            width: 4.5rem;
            height: 4.5rem;
            border-left: 0.4rem solid #041E41;
            border-top: 0.4rem solid #041E41; }
            @media only screen and (max-width: 47.9375em) {
              .modal__body .modal__content .modal__image .modal__image__inner .line {
                width: 3.5rem;
                height: 3.5rem; } }
      .modal__body .modal__content .modal__info {
        display: flex;
        align-items: center;
        margin-left: 4rem;
        width: 100%;
        position: relative; }
        @media only screen and (max-width: 47.9375em) {
          .modal__body .modal__content .modal__info {
            margin-left: 1.9rem;
            padding-bottom: 3rem;
            width: auto; } }
        .modal__body .modal__content .modal__info::before, .modal__body .modal__content .modal__info::after {
          position: absolute;
          content: '';
          display: block;
          background: #041E41;
          bottom: 0;
          right: 0; }
        .modal__body .modal__content .modal__info::before {
          max-width: 50rem;
          width: 100%;
          height: 0.1rem; }
          @media only screen and (max-width: 47.9375em) {
            .modal__body .modal__content .modal__info::before {
              width: 50%; } }
        .modal__body .modal__content .modal__info::after {
          max-width: 50rem;
          width: 0.1rem;
          max-height: 38.9rem;
          height: 100%; }
        .modal__body .modal__content .modal__info .line {
          bottom: 0.6rem;
          right: 0.6rem;
          position: absolute;
          width: 4.5rem;
          height: 4.5rem;
          border-bottom: 0.4rem solid #041E41;
          border-right: 0.4rem solid #041E41; }
          @media only screen and (max-width: 47.9375em) {
            .modal__body .modal__content .modal__info .line {
              width: 3.5rem;
              height: 3.5rem; } }
        .modal__body .modal__content .modal__info__inner {
          max-width: 45rem; }
          @media only screen and (max-width: 47.9375em) {
            .modal__body .modal__content .modal__info__inner {
              margin-top: 3rem;
              padding-right: 3rem; } }
          .modal__body .modal__content .modal__info__inner__title {
            font-family: "Druk Wide Trial", sans-serif;
            font-weight: 500;
            font-size: 3.2rem;
            line-height: 4rem;
            color: #222222; }
            @media only screen and (max-width: 47.9375em) {
              .modal__body .modal__content .modal__info__inner__title {
                font-size: 2.4rem;
                line-height: 3.2rem; } }
          .modal__body .modal__content .modal__info__inner hr {
            margin: 1.2rem 0;
            background: #009BDE;
            height: 0.1rem;
            max-width: 12.6rem; }
            @media only screen and (max-width: 47.9375em) {
              .modal__body .modal__content .modal__info__inner hr {
                margin: 0.8rem 0 !important; } }
          .modal__body .modal__content .modal__info__inner__position {
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #565C62; }
          .modal__body .modal__content .modal__info__inner__contact__wrap {
            margin: 3.4rem 0 3rem;
            display: grid;
            grid-template-columns: 3.4rem 1fr;
            grid-gap: 2rem 1.6rem; }
            @media only screen and (max-width: 47.9375em) {
              .modal__body .modal__content .modal__info__inner__contact__wrap {
                margin: 2.4rem 0 2rem;
                grid-gap: 1.6rem 0.6rem; } }
            .modal__body .modal__content .modal__info__inner__contact__wrap .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 3.4rem;
              height: 3.4rem;
              position: relative;
              background: #041E41; }
              .modal__body .modal__content .modal__info__inner__contact__wrap .icon::before {
                display: block;
                content: '';
                position: absolute;
                top: -0.4rem;
                left: -0.4rem;
                border: 0.1rem solid #041E41;
                width: 3.4rem;
                height: 3.4rem; }
          .modal__body .modal__content .modal__info__inner__text {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #565C62; }
  .modal.open {
    visibility: visible;
    opacity: 1; }

.login-page header {
  background: #041E41; }

.login-page .login {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: url("../images/login-bg.jpg") no-repeat 100% 100%;
  background-size: cover; }
  .login-page .login__inner {
    max-width: 84rem;
    width: 100%;
    position: absolute;
    top: 20rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 0 7rem;
    background-color: #041E41;
    border-radius: 0.2rem; }
    @media only screen and (max-width: 47.9375em) {
      .login-page .login__inner {
        position: static;
        margin-right: 1.6rem;
        margin-top: 20rem;
        padding: 0;
        background-color: transparent; } }
    .login-page .login__inner .login__form {
      background-color: #041E41;
      max-width: 40rem;
      width: 100%; }
      @media only screen and (max-width: 47.9375em) {
        .login-page .login__inner .login__form {
          padding: 3rem 1.6rem; } }
      .login-page .login__inner .login__form__title {
        font-family: "Druk Wide Trial", sans-serif;
        font-size: 3.2rem;
        line-height: 4rem; }
        @media only screen and (max-width: 47.9375em) {
          .login-page .login__inner .login__form__title {
            font-size: 2.4rem;
            text-align: center; } }
      .login-page .login__inner .login__form hr {
        max-width: 12rem;
        margin-top: 1.2rem;
        background-color: #ffffff;
        height: 0.1rem; }
        @media only screen and (max-width: 47.9375em) {
          .login-page .login__inner .login__form hr {
            margin: 1.2rem auto 0; } }
      .login-page .login__inner .login__form .form-group {
        margin-top: 2.4rem; }
      .login-page .login__inner .login__form .form-btn {
        margin-top: 3rem;
        margin-bottom: 1.2rem; }
        .login-page .login__inner .login__form .form-btn a.btn-orange {
          padding: 1.3rem 0;
          width: 100%;
          background-color: #FFBD21;
          text-decoration: none;
          transition: .3s ease; }
          .login-page .login__inner .login__form .form-btn a.btn-orange:hover {
            background-color: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%); }
      .login-page .login__inner .login__form .forgot-password-link {
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #F8F9FA;
        text-underline-offset: 0.5rem;
        transition: .3s ease-in-out; }
        .login-page .login__inner .login__form .forgot-password-link:hover {
          color: #FFBD21;
          cursor: pointer; }
      .login-page .login__inner .login__form .sign-up {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        padding-left: 4.7rem; }
        @media only screen and (max-width: 47.9375em) {
          .login-page .login__inner .login__form .sign-up {
            padding-left: 0; } }
        .login-page .login__inner .login__form .sign-up__text {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #DEE2E6; }
        .login-page .login__inner .login__form .sign-up__link {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #009BDE;
          text-decoration: none;
          margin-left: 1.6rem;
          transition: .3s ease-in-out;
          position: relative; }
          @media only screen and (max-width: 47.9375em) {
            .login-page .login__inner .login__form .sign-up__link {
              font-size: 1.6rem; } }
          .login-page .login__inner .login__form .sign-up__link svg {
            transition: .3s ease-in-out;
            position: absolute;
            top: 50%;
            margin-top: -0.4rem;
            right: -2rem; }
          .login-page .login__inner .login__form .sign-up__link:hover {
            color: #FFBD21;
            cursor: pointer; }
            .login-page .login__inner .login__form .sign-up__link:hover svg {
              stroke: #FFBD21;
              right: -2.5rem; }

.login__desktop {
  display: flex !important;
  padding-left: 1.6rem !important;
  padding-right: 1.6rem !important; }

.about {
  position: relative;
  min-height: 87.6rem;
  overflow: hidden;
  font-family: "Gotham Pro", sans-serif;
  min-width: 100vw; }
  .about__inner .swiper-container {
    min-width: 100vw; }
    .about__inner .swiper-container .swiper-wrapper {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
      .about__inner .swiper-container .swiper-wrapper .swiper-slide {
        padding-top: 10rem; }
        @media only screen and (max-width: 47.9375em) {
          .about__inner .swiper-container .swiper-wrapper .swiper-slide {
            width: 100%; } }
        @media only screen and (max-width: 47.9375em) {
          .about__inner .swiper-container .swiper-wrapper .swiper-slide .container {
            width: 100%; } }
        .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info {
          margin-top: 9.5rem; }
          @media only screen and (max-width: 47.9375em) {
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info {
              min-height: 32.5rem;
              background-color: rgba(4, 30, 65, 0.4);
              border-radius: 0.2rem;
              padding: 2rem 1.4rem;
              position: absolute;
              bottom: 18.4rem;
              width: 90%; } }
  @media only screen and (max-width: 47.9375em) and (max-width: 47.9375em) {
    .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info {
      min-height: 0; } }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info .about__title {
            margin: 1.1rem 0 1.2rem;
            font-family: "Druk Wide Trial", sans-serif;
            font-weight: 500;
            font-size: 6.4rem;
            letter-spacing: 0.1rem;
            line-height: 7.2rem;
            color: #ffffff; }
            @media only screen and (max-width: 47.9375em) {
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info .about__title {
                font-size: 2.4rem;
                line-height: 3.2rem; } }
          @media only screen and (max-width: 47.9375em) {
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info .text-primary {
              font-size: 1.4rem;
              line-height: 2.2rem; } }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info hr {
            background-color: #DEE2E6;
            height: 0.1rem;
            max-width: 49.5rem; }
            @media only screen and (max-width: 47.9375em) {
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info hr {
                max-width: 23.3rem; } }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info hr {
                display: none; } }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-info .about__text {
            margin-top: 2.4rem;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.8rem;
            color: #DEE2E6;
            max-width: 49.5rem; }
        .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search {
          margin-top: 24.3rem; }
          @media only screen and (max-width: 80em) {
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search {
              grid-template-columns: 1fr 16rem;
              grid-column-gap: 2rem; }
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .container {
                max-width: 50rem; } }
          @media only screen and (max-width: 47.9375em) {
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search {
              bottom: 12rem;
              position: absolute;
              grid-template-columns: 1fr 4.8rem;
              grid-column-gap: 1.2rem;
              right: 1.6rem;
              left: 1.6rem; }
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search span {
                display: none; }
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .form-text {
                display: none; } }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap {
            position: relative;
            display: flex;
            justify-content: center; }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap::before {
              position: absolute;
              content: '';
              background: url("../images/search-dark.png") no-repeat center center;
              width: 1.8rem;
              height: 1.8rem;
              left: 1.5rem;
              top: 50%;
              margin-top: -0.9rem; }
              @media only screen and (max-width: 47.9375em) {
                .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap::before {
                  left: 0.8rem; } }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap input {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 1.2rem 0 1.2rem 4.6rem;
              background-color: rgba(255, 255, 255, 0.8);
              border: 0.1rem solid #122741;
              border-radius: 0.2rem;
              line-height: 2.4rem; }
              @media only screen and (max-width: 47.9375em) {
                .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap input {
                  font-size: 1.4rem;
                  padding: 1.2rem 0 1.2rem 3.6rem; } }
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap input::placeholder {
                font-weight: 400;
                font-size: 1.6rem;
                color: #565C62;
                outline: none; }
                @media only screen and (max-width: 47.9375em) {
                  .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap input::placeholder {
                    font-size: 1.4rem !important;
                    border-radius: 0 !important; } }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap .btn-search {
              background-color: #009BDE;
              max-width: 20rem;
              width: 100%;
              margin-left: 1.2rem; }
              .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap .btn-search svg {
                margin-left: 0.9rem; }
                @media only screen and (max-width: 47.9375em) {
                  .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap .btn-search svg {
                    margin-left: 0; } }
              @media only screen and (max-width: 47.9375em) {
                .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .search__wrap .btn-search {
                  margin-left: 0.8rem;
                  width: 5.8rem !important; } }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .form-text {
            margin-top: 1.3rem;
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #B6B6B6; }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide .slide-form-search .form-text span {
              color: #009BDE;
              padding-bottom: 0.5rem;
              border-bottom: 0.1rem solid #009BDE; }
        .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-1 {
          background: url("../images/main-page/about/slide-1.jpg") no-repeat 100% 100%;
          background-size: cover; }
        .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 {
          background: url("../images/main-page/about/slide-2.jpg") no-repeat 100% 100%;
          background-size: cover; }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .about__title {
            max-width: 54.4rem; }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .slide-form-search {
            grid-template-rows: 4.2rem;
            margin-top: 12.4rem; }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .slide-form-search input {
              height: 1.6rem; }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .slide-form-search button {
              margin-top: 0;
              padding: 0; }
              @media only screen and (max-width: 47.9375em) {
                .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .slide-form-search button {
                  width: 4.8rem !important;
                  padding-left: 0;
                  padding-right: 0; }
                  .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .slide-form-search button svg {
                    padding: 0;
                    margin: 0; } }
              @media (min-width: 180rem) {
                .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 .slide-form-search button {
                  height: 2.4rem; } }
          .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 button {
            margin-top: 3.2rem;
            padding: 1rem;
            max-width: 16.5rem;
            width: 100%;
            background-color: #FFBD21; }
            .about__inner .swiper-container .swiper-wrapper .swiper-slide.slide-2 button:hover {
              background-color: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%); }
    .about__inner .swiper-container .swiper-button-prev::after,
    .about__inner .swiper-container .swiper-button-next::after {
      display: none; }
    .about__inner .swiper-container .swiper-button-next,
    .about__inner .swiper-container .swiper-button-prev {
      margin-top: -12rem;
      border: none;
      outline: none; }
      @media only screen and (max-width: 47.9375em) {
        .about__inner .swiper-container .swiper-button-next,
        .about__inner .swiper-container .swiper-button-prev {
          margin-top: -15rem; } }
    .about__inner .swiper-container .swiper-button-prev {
      margin-left: 9.5rem;
      background: url("../images/main-page/about/arrow-back.png") no-repeat; }
      @media only screen and (max-width: 47.9375em) {
        .about__inner .swiper-container .swiper-button-prev {
          margin-top: -13rem; } }
      @media (max-width: 150rem) {
        .about__inner .swiper-container .swiper-button-prev {
          margin-left: 4rem; } }
      @media (max-width: 140rem) {
        .about__inner .swiper-container .swiper-button-prev {
          margin-left: 1.5rem; } }
      @media (min-width: 76.7rem) and (max-width: 100rem) {
        .about__inner .swiper-container .swiper-button-prev {
          margin-top: -1rem; } }
    .about__inner .swiper-container .btn__swiper__wrap {
      display: flex;
      align-items: center; }
    .about__inner .swiper-container .swiper-button-next {
      background: url("../images/main-page/about/arrow-next.png") no-repeat;
      margin-right: 9.5rem; }
      @media (max-width: 150rem) {
        .about__inner .swiper-container .swiper-button-next {
          margin-right: 4rem; } }
      @media (max-width: 140rem) {
        .about__inner .swiper-container .swiper-button-next {
          margin-right: 2.5rem; } }
      @media only screen and (max-width: 47.9375em) {
        .about__inner .swiper-container .swiper-button-next {
          margin-top: -13rem; } }
      @media (min-width: 76.7rem) and (max-width: 100rem) {
        .about__inner .swiper-container .swiper-button-next {
          margin-top: -1rem; } }
    .about__inner .swiper-container .swiper-pagination {
      margin-bottom: 8.5rem; }
      .about__inner .swiper-container .swiper-pagination .swiper-pagination-bullet {
        height: 0.2rem;
        width: 13.1rem;
        border-radius: 0;
        background: rgba(255, 255, 255, 0.28); }
        @media only screen and (max-width: 47.9375em) {
          .about__inner .swiper-container .swiper-pagination .swiper-pagination-bullet {
            display: none; } }
        .about__inner .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: #ffffff; }
  @media only screen and (max-width: 80em) {
    .about {
      height: calc(100vh - 9.2rem); } }

.speakers {
  background-color: #F0EFEF;
  padding: 8rem 0 10rem;
  font-family: "Gotham Pro", sans-serif; }
  .speakers__info {
    padding-left: 3rem;
    border-left: 0.1rem solid #009BDE;
    margin-bottom: 6rem; }
    .speakers__info .text-title {
      margin-top: 0.8rem;
      letter-spacing: 0.1rem; }
  .speakers__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    margin-bottom: 6rem; }
    .speakers__list__item {
      padding: 3rem 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
      border: 0.12rem solid #041E41; }
      .speakers__list__item__border-image {
        border: 0.12rem solid #041E41;
        padding: 1.7rem; }
        .speakers__list__item__border-image .speakers__list__item__image {
          width: 100%;
          border: 0.1rem solid #DEE2E6; }
      .speakers__list__item__name {
        margin-top: 2.7rem;
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 2.8rem;
        color: #041E41; }
      .speakers__list__item hr {
        width: 12rem;
        height: 0.1rem;
        background-color: #009BDE; }
      .speakers__list__item__text {
        margin-top: 1.6rem;
        text-align: center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #565C62;
        letter-spacing: 0.01rem; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .speakers__list__item__text {
            min-height: 27rem;
            text-align: center; } }
      .speakers__list__item:hover {
        transition: .3s all;
        background-color: #041E41;
        cursor: pointer; }
        .speakers__list__item:hover .speakers__list__item__border-image {
          border-color: #ffffff; }
          .speakers__list__item:hover .speakers__list__item__border-image .speakers__list__item__image {
            border: 0.1rem solid #DEE2E6; }
        .speakers__list__item:hover .speakers__list__item__name {
          color: #ffffff; }
        .speakers__list__item:hover .speakers__list__item__text {
          color: #DEE2E6; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .speakers__list__item {
          display: block;
          text-align: center;
          width: 36rem;
          margin-bottom: 4rem; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .speakers__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; } }
    @media only screen and (max-width: 80em) {
      .speakers__list {
        grid-template-columns: repeat(2, 1fr); } }
    @media only screen and (max-width: 47.9375em) {
      .speakers__list {
        grid-template-columns: repeat(1, 1fr); } }
  .speakers .speakers-link {
    padding: 1.2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #009BDE;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    transition: .3s all; }
    .speakers .speakers-link::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4.9rem;
      height: 0.1rem;
      background-color: #009BDE;
      transition: .3s all; }
    .speakers .speakers-link::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.1rem;
      background-color: #009BDE;
      transition: .3s all; }
    .speakers .speakers-link svg {
      transition: .3s all;
      margin-left: 1rem; }
    .speakers .speakers-link svg * {
      fill: #009BDE; }
    .speakers .speakers-link:hover::after {
      background-color: #32dbb2; }
    .speakers .speakers-link:hover svg {
      margin-top: 1rem;
      stroke: #32dbb2;
      fill: #32dbb2; }
    .speakers .speakers-link:hover {
      color: #32dbb2; }
    .speakers .speakers-link:hover::before {
      width: 100%;
      transition: .3s all;
      background-color: #32dbb2; }
  @media only screen and (max-width: 47.9375em) {
    .speakers {
      padding: 4rem 0 6rem; }
      .speakers .speakers__info {
        padding-left: 1.6rem;
        border-left-color: #041E41; }
        .speakers .speakers__info .speakers__title {
          font-size: 1.4rem;
          line-height: 2.2rem; }
        .speakers .speakers__info .text-title {
          font-size: 3.2rem;
          line-height: 4rem; }
      .speakers .speakers__list {
        grid-gap: 3rem; } }

.premiere-speakers {
  background: url("../images/main-page/premiere-speakers/premiere-speakers-bg.jpg") no-repeat 100% 100%;
  background-size: cover;
  font-family: "Gotham Pro", sans-serif;
  padding: 10rem 0 27.7rem; }
  .premiere-speakers__info {
    border-left: 0.1rem solid #DEE2E6;
    padding-left: 3rem; }
    .premiere-speakers__info .text-title {
      font-size: 6.4rem;
      line-height: 7.2rem;
      font-family: "Druk Wide Trial", sans-serif;
      font-weight: 500;
      color: #ffffff; }
    .premiere-speakers__info .premiere-speakers__text {
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.8rem;
      color: #F0EFEF;
      max-width: 51.3rem;
      margin-top: 2rem; }
  .premiere-speakers .btn-orange {
    margin-left: 3rem;
    max-width: 22rem;
    padding: 1.2rem 0;
    width: 100%;
    margin-top: 4rem; }
  @media only screen and (max-width: 47.9375em) {
    .premiere-speakers {
      padding: 4rem 0 26.9rem; }
      .premiere-speakers .container {
        margin: 0; }
        .premiere-speakers .container .premiere-speakers__info {
          padding-left: 1.6rem; }
          .premiere-speakers .container .premiere-speakers__info__title {
            margin-bottom: 0.8rem;
            font-size: 1.4rem;
            line-height: 2.2rem; }
          .premiere-speakers .container .premiere-speakers__info .text-title {
            font-size: 3.2rem;
            line-height: 4rem; }
        .premiere-speakers .container .btn-orange {
          margin-left: 1.6rem; } }

.subtopics {
  background-color: #ffffff;
  padding: 8rem 0 2rem;
  font-family: "Gotham Pro", sans-serif; }
  .subtopics__control {
    margin-bottom: 3rem; }
    .subtopics__control .swiper-container {
      background-color: #F8F9FA;
      border-radius: 0.2rem;
      padding: 0.8rem 0;
      overflow: hidden; }
      .subtopics__control .swiper-container .swiper-wrapper {
        position: relative; }
        .subtopics__control .swiper-container .swiper-wrapper .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 4.5rem;
          border-right: 0.1rem solid #DADADA;
          padding-right: 1.6rem;
          padding-left: 1.6rem; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .subtopics__control .swiper-container .swiper-wrapper .swiper-slide {
              max-width: 20rem;
              width: 100%; } }
          @media only screen and (max-width: 47.9375em) {
            .subtopics__control .swiper-container .swiper-wrapper .swiper-slide {
              display: flex;
              justify-content: center; } }
          .subtopics__control .swiper-container .swiper-wrapper .swiper-slide .subtopics__filter__link {
            padding: 1.1rem 0;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            text-decoration: none;
            font-size: 1.2rem;
            line-height: 2.2rem;
            color: #565C62;
            border-radius: 0.2rem;
            cursor: pointer;
            outline: none;
            border: none;
            background: transparent;
            transition: .3s linear; }
            .subtopics__control .swiper-container .swiper-wrapper .swiper-slide .subtopics__filter__link.active {
              background: #041E41;
              color: #ffffff; }
    .subtopics__control .btn-wrap {
      position: relative;
      height: 0;
      background-color: silver;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .subtopics__control .btn-wrap .swiper-button-prev,
      .subtopics__control .btn-wrap .swiper-button-next {
        position: relative;
        border: none;
        outline: none;
        top: -2rem; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .subtopics__control .btn-wrap .swiper-button-prev,
          .subtopics__control .btn-wrap .swiper-button-next {
            display: none; } }
        @media only screen and (max-width: 80em) {
          .subtopics__control .btn-wrap .swiper-button-prev,
          .subtopics__control .btn-wrap .swiper-button-next {
            display: none; } }
        .subtopics__control .btn-wrap .swiper-button-prev::after,
        .subtopics__control .btn-wrap .swiper-button-next::after {
          display: none; }
      .subtopics__control .btn-wrap .swiper-button-prev {
        left: -5rem; }
      .subtopics__control .btn-wrap .swiper-button-next {
        left: 5rem; }
  .subtopics__info {
    max-width: 59rem;
    padding-left: 3rem;
    border-left: 0.1rem solid #041E41;
    margin-bottom: 6rem; }
  .subtopics__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    margin-bottom: 6rem; }
    .subtopics__list__item {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
      border: 0.12rem solid #041E41;
      position: relative;
      transition: .3s ease-in-out;
      cursor: pointer; }
      .subtopics__list__item:hover .subtopics__list__item__wrap {
        background-color: #041E41; }
        .subtopics__list__item:hover .subtopics__list__item__wrap .subtopics__list__item__name,
        .subtopics__list__item:hover .subtopics__list__item__wrap .subtopics__list__item__text {
          color: #ffffff;
          transition: .3s ease-in-out; }
      @media only screen and (max-width: 47.9375em) {
        .subtopics__list__item:first-child {
          min-height: 18.9rem; } }
      .subtopics__list__item:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 0;
        border-bottom: 0;
        padding-right: 4rem; }
        @media only screen and (max-width: 47.9375em) {
          .subtopics__list__item:first-child {
            border-left: 0;
            border-top: 0;
            border-right: 0.12rem solid #041E41;
            border-bottom: 0.12rem solid #041E41; } }
        .subtopics__list__item:first-child .subtopics__list__item__name {
          font-family: "Druk Wide Trial", sans-serif;
          color: #009BDE;
          font-size: 2rem;
          line-height: 2.8rem;
          align-self: flex-start; }
        .subtopics__list__item:first-child hr {
          margin: 1rem 0;
          max-width: 14rem;
          width: 100%;
          height: 0.1rem;
          background-color: #009BDE;
          align-self: flex-start; }
        .subtopics__list__item:first-child .subtopics__list__item__text {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #565C62;
          margin: 0; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .subtopics__list__item:first-child .subtopics__list__item__text {
              max-width: 27.5rem; } }
      .subtopics__list__item .subtopics__list__item__image {
        width: 100%; }
      .subtopics__list__item .subtopics__list__item__wrap {
        padding: 1.6rem;
        position: absolute;
        max-height: 16.9rem;
        height: 100%;
        bottom: 0;
        left: 0;
        max-width: 30.2rem;
        width: 100%;
        background-color: #ffffff; }
        .subtopics__list__item .subtopics__list__item__wrap .subtopics__list__item__name {
          font-size: 2rem;
          line-height: 2.8rem;
          color: #041E41;
          margin-bottom: 0.8rem; }
        .subtopics__list__item .subtopics__list__item__wrap hr {
          width: 12rem;
          height: 0.1rem;
          background-color: #009BDE; }
        .subtopics__list__item .subtopics__list__item__wrap .subtopics__list__item__text {
          margin-top: 1.2rem;
          text-align: left;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #565C62; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .subtopics__list__item hr {
          text-align: left; } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .subtopics__list__item {
          max-width: 36rem;
          width: 100%;
          margin-bottom: 4rem; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .subtopics__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; } }
    @media only screen and (max-width: 80em) {
      .subtopics__list {
        grid-template-columns: repeat(2, 1fr); } }
    @media only screen and (max-width: 47.9375em) {
      .subtopics__list {
        grid-template-columns: repeat(1, 1fr); } }
  @media only screen and (max-width: 47.9375em) {
    .subtopics {
      padding: 4rem 0 6rem; }
      .subtopics__info {
        padding-left: 1.6rem;
        border-left-color: #041E41; }
        .subtopics__info__title {
          font-size: 1.4rem;
          line-height: 2.2rem; }
        .subtopics__info__text {
          font-size: 3.2rem;
          line-height: 4rem; }
      .subtopics__list {
        grid-gap: 3rem; } }

.reviews {
  padding: 8rem 0 10rem;
  background: url("../images/main-page/reviews/reviews-bg.jpg") no-repeat 100% 100%;
  background-size: cover; }
  @media only screen and (max-width: 47.9375em) {
    .reviews {
      padding: 4rem 0 6rem; } }
  .reviews__info {
    max-width: 60rem;
    padding-left: 3rem;
    border-left: 0.1rem solid #ffffff; }
    @media only screen and (max-width: 47.9375em) {
      .reviews__info {
        padding-left: 1.6rem; } }
    .reviews__info .text-title {
      color: #ffffff; }
      @media only screen and (max-width: 47.9375em) {
        .reviews__info .text-title {
          font-size: 3.2rem;
          line-height: 4rem; } }
  .reviews .reviews__swiper__container {
    overflow: hidden;
    position: relative; }
    @media only screen and (max-width: 47.9375em) {
      .reviews .reviews__swiper__container {
        padding-left: 1.6rem;
        padding-right: 1.6rem; } }
    .reviews .reviews__swiper__container .reviews__swiper__wrapper {
      margin: 0; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .reviews .reviews__swiper__container .reviews__swiper__wrapper {
          display: flex;
          justify-content: space-between;
          margin: 0 1.6rem; } }
      .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide {
        padding: 4rem;
        color: #ffffff;
        background-color: #122741;
        border: 0.1rem solid rgba(255, 255, 255, 0.2);
        border-radius: 0.2rem; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide {
            max-width: 40rem;
            width: 100%; } }
        @media only screen and (max-width: 47.9375em) {
          .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide {
            max-width: 100%;
            box-sizing: border-box;
            padding: 3rem 2rem; } }
        .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide .comments__wrap {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide .comments__wrap .comments {
            text-align: center;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #F0EFEF;
            font-style: italic; }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide .comments__wrap .comments {
                display: block;
                max-width: 28rem; } }
          .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide .comments__wrap .author {
            font-weight: 700;
            margin-top: 1.5rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #ffffff; }
          .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide .comments__wrap hr {
            margin: 1rem auto;
            background-color: #009BDE;
            max-width: 9.1rem;
            width: 100%;
            height: 0.1rem; }
          .reviews .reviews__swiper__container .reviews__swiper__wrapper .reviews__swiper__slide .comments__wrap .subtitle {
            line-height: 2.2rem;
            color: #DEE2E6;
            font-size: 1.4rem; }
    .reviews .reviews__swiper__container .button-wrap {
      margin: 2rem auto;
      display: flex;
      outline: none;
      border: none; }
      .reviews .reviews__swiper__container .button-wrap .swiper-button-prev,
      .reviews .reviews__swiper__container .button-wrap .swiper-button-next {
        position: relative;
        background: transparent;
        border: none;
        outline: none;
        top: 0;
        margin: 0; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .reviews .reviews__swiper__container .button-wrap .swiper-button-prev,
          .reviews .reviews__swiper__container .button-wrap .swiper-button-next {
            display: none; } }
        .reviews .reviews__swiper__container .button-wrap .swiper-button-prev::after,
        .reviews .reviews__swiper__container .button-wrap .swiper-button-next::after {
          display: none; }
      .reviews .reviews__swiper__container .button-wrap .swiper-button-prev {
        margin-right: 4.2rem; }
      @media only screen and (max-width: 47.9375em) {
        .reviews .reviews__swiper__container .button-wrap .swiper-button-next {
          margin-left: 2.8rem; }
          .reviews .reviews__swiper__container .button-wrap .swiper-button-next svg * {
            fill: #ffffff; } }
      .reviews .reviews__swiper__container .button-wrap .swiper-pagination {
        display: block; }
      .reviews .reviews__swiper__container .button-wrap .swiper-pagination-fraction {
        font-size: 2rem;
        display: block;
        color: #ffffff; }
    .reviews .reviews__swiper__container .swiper-pagination {
      margin-top: 10rem;
      position: static;
      display: block; }
      @media only screen and (max-width: 47.9375em) {
        .reviews .reviews__swiper__container .swiper-pagination {
          position: absolute;
          top: -7rem;
          left: 6.5rem;
          font-family: "Druk Wide Trial", sans-serif;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2.4rem;
          height: 2.4rem;
          width: 6rem; }
          .reviews .reviews__swiper__container .swiper-pagination .swiper-pagination-current {
            color: #009BDE; } }
    .reviews .reviews__swiper__container .swiper-pagination-bullet {
      height: 0.2rem;
      background-color: #ffffff;
      width: 13.1rem;
      border-radius: 0; }

.cooperation {
  padding: 8rem 0 10rem;
  background-color: #ffffff;
  position: relative; }
  .cooperation-bg {
    position: absolute;
    right: 0;
    top: 3rem; }
  .cooperation__info {
    padding-left: 3rem;
    border-left: 0.1rem solid #041E41;
    max-width: 64rem;
    margin-bottom: 6rem; }
  .cooperation__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    grid-gap: 4rem; }
    .cooperation__list__item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F0EFEF;
      min-height: 12.4rem;
      border-bottom: 0.1rem solid #041E41;
      border-left: 0.1rem solid #041E41;
      position: relative; }
      .cooperation__list__item::before, .cooperation__list__item::after {
        position: absolute;
        content: '';
        height: 0.1rem;
        background-color: #041E41;
        transition: .3s ease; }
      .cooperation__list__item::before {
        max-width: 8rem;
        width: 100%;
        top: 0;
        left: 0; }
      .cooperation__list__item::after {
        max-height: 8rem;
        width: 0.1rem;
        height: 100%;
        bottom: 0;
        right: 0; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .cooperation__list__item {
          max-width: 16rem;
          width: 100%;
          margin-bottom: 4rem; } }
    @media only screen and (max-width: 47.9375em) {
      .cooperation__list {
        display: none; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .cooperation__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; } }
  @media only screen and (max-width: 47.9375em) {
    .cooperation {
      padding: 4rem 0 6rem; }
      .cooperation .cooperation-bg {
        display: none; }
      .cooperation .cooperation__info {
        padding-left: 1.6rem; }
        .cooperation .cooperation__info .text-title {
          font-size: 2.4rem;
          line-height: 3.2rem; } }
  .cooperation-slider {
    display: none; }
    .cooperation-slider .swiper-wrapper {
      display: flex; }
      .cooperation-slider .swiper-wrapper .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 5.8rem; }
        .cooperation-slider .swiper-wrapper .swiper-slide img {
          max-height: 5.9rem;
          height: 100%; }
    @media only screen and (max-width: 47.9375em) {
      .cooperation-slider {
        display: flex; } }

.about-page {
  background: #041E41;
  font-family: "Gotham Pro", sans-serif; }
  .about-page .main {
    min-height: 92rem;
    overflow-x: hidden; }
    @media only screen and (max-width: 47.9375em) {
      .about-page .main {
        min-height: calc(100vh - 6.4rem);
        padding-bottom: 2rem; } }
    .about-page .main__inner {
      margin-top: 20rem;
      display: grid;
      grid-template-columns: minmax(10rem, 56.8rem) 1fr;
      grid-column-gap: 3.5rem; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .about-page .main__inner {
          display: flex;
          justify-content: space-between; } }
      @media only screen and (max-width: 80em) {
        .about-page .main__inner {
          grid-template-columns: minmax(10rem, 40rem) 1fr; } }
      @media only screen and (max-width: 47.9375em) {
        .about-page .main__inner {
          display: flex;
          flex-direction: column-reverse;
          margin-top: 6.5rem; } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .about-page .main__inner .main__info {
          width: 50%; } }
      .about-page .main__inner .main__info__title {
        margin-top: 1.2rem;
        font-family: "Druk Wide Trial", sans-serif;
        color: #ffffff;
        font-size: 6.4rem;
        line-height: 7.2rem; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .main__inner .main__info__title {
            font-size: 3.2rem;
            line-height: 4rem; } }
      .about-page .main__inner .main__info hr {
        margin: 1.2rem 0 2.4rem;
        height: 0.1rem;
        background: #DEE2E6; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .main__inner .main__info hr {
            max-width: 33.3rem; } }
      .about-page .main__inner .main__info__text {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #DEE2E6;
        margin-bottom: 1.2rem; }
      .about-page .main__inner .main__image__wrap {
        position: relative; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .main__inner .main__image__wrap {
            display: flex;
            justify-content: center;
            margin-bottom: 1.5rem; } }
        .about-page .main__inner .main__image__wrap .main__image {
          width: 100%;
          height: auto;
          display: block;
          top: -10.2rem;
          position: absolute;
          animation: fade 10s linear infinite; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .about-page .main__inner .main__image__wrap .main__image {
              position: static; } }
          @media only screen and (max-width: 80em) {
            .about-page .main__inner .main__image__wrap .main__image {
              max-width: 40rem;
              width: 100%;
              max-height: 40rem;
              height: 100%;
              top: 0; } }
          @media only screen and (max-width: 47.9375em) {
            .about-page .main__inner .main__image__wrap .main__image {
              position: static;
              max-width: 31.4rem; } }

@keyframes fade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .about-page .about {
    padding: 8rem 0;
    background-color: #F0EFEF;
    height: auto; }
    @media only screen and (max-width: 47.9375em) {
      .about-page .about {
        padding: 4rem 0 6rem; } }
    .about-page .about__info {
      max-width: 70rem;
      padding-left: 3rem;
      border-left: 0.1rem solid #041E41;
      margin-bottom: 6rem; }
      @media only screen and (max-width: 47.9375em) {
        .about-page .about__info {
          margin-bottom: 3rem; } }
      @media only screen and (max-width: 47.9375em) {
        .about-page .about__info {
          padding-left: 1.6rem; } }
      .about-page .about__info__title {
        margin-top: 1.2rem; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .about__info__title {
            margin-top: 0.8rem; } }
      .about-page .about__info__text {
        margin-top: 1.2rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #565C62; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .about__info__text {
            margin-top: 0.8rem; } }
    .about-page .about__card__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .about-page .about__card__list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; } }
      @media only screen and (max-width: 80em) {
        .about-page .about__card__list {
          grid-template-columns: repeat(2, 1fr); } }
      @media only screen and (max-width: 47.9375em) {
        .about-page .about__card__list {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 3rem; } }
      .about-page .about__card__list__item {
        padding: 2rem;
        background-color: #ffffff;
        border: 0.1rem solid #009BDE; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .about-page .about__card__list__item {
            max-width: 36rem;
            width: 100%;
            margin-bottom: 4rem; } }
        .about-page .about__card__list__item .number {
          color: #009BDE;
          font-family: "Druk Wide Trial", sans-serif;
          font-size: 2.4rem;
          line-height: 3.2rem; }
        .about-page .about__card__list__item .title {
          font-family: "Druk Wide Trial", sans-serif;
          margin: 1.2rem 0 0.8rem;
          color: #041E41;
          font-size: 1.6rem;
          line-height: 2.4rem; }
        .about-page .about__card__list__item hr {
          background-color: #009BDE;
          max-width: 14rem;
          height: 0.1rem; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .about-page .about__card__list__item hr {
              text-align: left; } }
        .about-page .about__card__list__item .text {
          margin-top: 0.8rem;
          color: #565C62;
          font-size: 1.4rem;
          line-height: 2.2rem; }
          .about-page .about__card__list__item .text .primary {
            color: #009BDE; }
  .about-page .leaders {
    background: #ffffff;
    padding: 8rem 0; }
    @media only screen and (max-width: 47.9375em) {
      .about-page .leaders {
        padding: 4rem 0 5rem; } }
    .about-page .leaders__info {
      padding-left: 3rem;
      border-left: 0.1rem solid #041E41;
      margin-bottom: 8.5rem; }
      @media only screen and (max-width: 47.9375em) {
        .about-page .leaders__info {
          padding-left: 1.6rem;
          margin-bottom: 3rem; } }
    .about-page .leaders__list {
      display: flex;
      flex-direction: column; }
      .about-page .leaders__list__item {
        display: grid;
        grid-template-columns: minmax(20rem, 32.5rem) minmax(20rem, 44.6rem);
        grid-column-gap: 6rem;
        border-bottom: 0.1rem solid #DEE2E6;
        margin-bottom: 6rem;
        padding-bottom: 6rem; }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .about-page .leaders__list__item {
            display: flex; } }
        .about-page .leaders__list__item:last-child {
          margin-bottom: 0; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .leaders__list__item {
            display: flex;
            flex-direction: column;
            margin-bottom: 3rem;
            padding-bottom: 3rem; } }
        .about-page .leaders__list__item__wrap {
          border: 0.1rem solid #041E41; }
          @media only screen and (max-width: 47.9375em) {
            .about-page .leaders__list__item__wrap {
              max-width: 42rem;
              width: 100%; } }
          .about-page .leaders__list__item__wrap .leaders__image {
            position: relative;
            top: 1.2rem;
            left: 1.2rem; }
            @media only screen and (min-width: 112.5em) {
              .about-page .leaders__list__item__wrap .leaders__image {
                height: 100%; } }
            @media only screen and (max-width: 47.9375em) {
              .about-page .leaders__list__item__wrap .leaders__image {
                top: 1.5rem;
                left: 0.8rem;
                max-width: 45rem;
                width: 100%; } }
        .about-page .leaders__list__item__information {
          padding-top: 1.4rem; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .about-page .leaders__list__item__information {
              margin-left: 4rem; } }
          @media only screen and (max-width: 47.9375em) {
            .about-page .leaders__list__item__information {
              padding-top: 2rem; } }
          .about-page .leaders__list__item__information__title {
            font-size: 2.4rem;
            line-height: 3.2rem;
            margin-bottom: 3rem;
            margin-top: 0.8rem; }
            @media only screen and (max-width: 47.9375em) {
              .about-page .leaders__list__item__information__title {
                margin-bottom: 2rem; } }
          .about-page .leaders__list__item__information__contact {
            color: #041E41;
            display: grid;
            grid-template-columns: minmax(1rem, 3.4rem) 1fr;
            grid-row-gap: 2rem;
            grid-column-gap: 1.6rem;
            align-items: center; }
            .about-page .leaders__list__item__information__contact .icon {
              min-height: 3.4rem;
              border: 0.1rem solid #041E41;
              position: relative;
              display: block; }
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .about-page .leaders__list__item__information__contact .icon {
                  display: flex;
                  width: 3.4rem;
                  margin-bottom: 1rem; } }
              .about-page .leaders__list__item__information__contact .icon::before {
                content: '';
                background: url("../images/about-page/leaders/phone.png") no-repeat center center #041E41;
                position: absolute;
                width: 3.4rem;
                height: 3.4rem;
                top: 0.4rem;
                left: 0.4rem;
                display: inline-block; }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              .about-page .leaders__list__item__information__contact .icon-email {
                margin-top: 1rem; } }
            .about-page .leaders__list__item__information__contact .icon-email::before {
              background: url("../images/about-page/leaders/email.png") no-repeat center center #041E41; }
            .about-page .leaders__list__item__information__contact .contact__text {
              padding-top: 0.7rem;
              color: #041E41 !important;
              text-decoration: none !important; }
          .about-page .leaders__list__item__information__history {
            margin-top: 3rem;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #565C62; }
            @media only screen and (max-width: 47.9375em) {
              .about-page .leaders__list__item__information__history {
                margin-top: 2.4rem; } }
  .about-page .premiere-speakers .container {
    padding: 0 1.6rem; }
  .about-page .premiere-speakers__text {
    font-size: 1.6rem;
    line-height: 2.4rem; }
  .about-page .premiere-speakers button.btn-primary {
    background-color: transparent;
    border-bottom: 0.1rem solid #FFBD21;
    color: #FFBD21;
    max-width: 16.4rem;
    position: relative; }
    .about-page .premiere-speakers button.btn-primary svg * {
      fill: #FFBD21; }
    .about-page .premiere-speakers button.btn-primary::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 0.1rem;
      max-width: 7.9rem;
      width: 100%;
      background-color: #FFBD21; }
  .about-page .team {
    padding: 8rem 0;
    background-color: #ffffff; }
    .about-page .team__info {
      padding-left: 3rem;
      border-left: 0.1rem solid #041E41;
      margin-bottom: 6rem; }
      @media only screen and (max-width: 47.9375em) {
        .about-page .team__info {
          padding-left: 1.6rem;
          margin-bottom: 3rem; } }
    @media only screen and (max-width: 47.9375em) {
      .about-page .team {
        padding: 4rem 0 6rem; } }
    .about-page .team__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem 5rem; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .about-page .team__list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; } }
      @media only screen and (max-width: 80em) {
        .about-page .team__list {
          grid-template-columns: repeat(2, 1fr); } }
      @media only screen and (max-width: 47.9375em) {
        .about-page .team__list {
          grid-template-columns: 1fr;
          grid-row-gap: 0; } }
      .about-page .team__list__item {
        padding: 2rem 2rem 2rem 3rem;
        border: 0.1rem solid #041E41;
        position: relative;
        margin-bottom: 4rem; }
        .about-page .team__list__item:last-child {
          margin-bottom: 0; }
        @media only screen and (max-width: 47.9375em) {
          .about-page .team__list__item__image {
            object-fit: cover;
            max-width: 100%; } }
        @media only screen and (max-width: 47.9375em) {
          .about-page .team__list__item {
            margin: 0 0 3rem;
            padding: 1.2rem 1.2rem 1.2rem 3.7rem; } }
        .about-page .team__list__item__wrap {
          padding: 1.6rem;
          position: absolute;
          background-color: #ffffff;
          color: #041E41;
          bottom: 0;
          left: 0;
          max-width: 82%;
          width: 100%;
          transition: .3s ease-in-out;
          cursor: pointer; }
          .about-page .team__list__item__wrap__name {
            font-size: 2rem;
            line-height: 2.8rem; }
          .about-page .team__list__item__wrap hr {
            margin: 0.8rem 0 1.7rem;
            background: #009BDE;
            height: 0.1rem;
            max-width: 12rem; }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              .about-page .team__list__item__wrap hr {
                text-align: left; } }
          .about-page .team__list__item__wrap:hover {
            background-color: #041E41;
            color: #ffffff; }

.speaker-page .availability {
  padding: 10rem 0;
  background: url("../images/speaker-page/availability.png") no-repeat 100% 100% #F0EFEF;
  background-size: cover; }
  @media only screen and (max-width: 47.9375em) {
    .speaker-page .availability {
      padding: 5rem 0; } }
  .speaker-page .availability__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .speaker-page .availability__inner .availability__title {
      font-family: "Druk Wide Trial", sans-serif;
      font-size: 4rem;
      line-height: 4.8rem;
      color: #041E41; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page .availability__inner .availability__title {
          font-size: 2.4rem;
          line-height: 3.2rem; } }
    .speaker-page .availability__inner .availability__form {
      margin-top: 4rem;
      max-width: 50rem;
      width: 100%; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page .availability__inner .availability__form {
          margin-top: 3rem; } }
      .speaker-page .availability__inner .availability__form .form-group {
        margin-bottom: 2rem; }
        .speaker-page .availability__inner .availability__form .form-group .form-group__textarea {
          min-height: 16.2rem;
          max-width: 50rem; }
      .speaker-page .availability__inner .availability__form .btn-group {
        margin-top: 4rem; }
        @media only screen and (max-width: 47.9375em) {
          .speaker-page .availability__inner .availability__form .btn-group {
            margin-top: 3rem; } }
        .speaker-page .availability__inner .availability__form .btn-group button.btn-orange {
          padding: 1.4rem;
          width: 100%;
          display: inline-block;
          background: #FFBD21;
          border: 0.1rem solid #FFBD21; }
          .speaker-page .availability__inner .availability__form .btn-group button.btn-orange:hover {
            background: transparent;
            color: #FFBD21; }

.speaker-page__list {
  padding: 8rem 0;
  margin-top: 9.6rem;
  background: #F8F9FA; }
  @media only screen and (max-width: 47.9375em) {
    .speaker-page__list {
      padding: 4rem 0 6rem; } }
  .speaker-page__list .speaker__list__info {
    padding-left: 3rem;
    border-left: 0.1rem solid #041E41; }
    .speaker-page__list .speaker__list__info__title {
      margin: 1.2rem 0; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page__list .speaker__list__info__title {
          margin: 0.8rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .speaker-page__list .speaker__list__info {
        padding-left: 1.6rem; } }
    .speaker-page__list .speaker__list__info__text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #565C62;
      max-width: 55.6rem; }
  .speaker-page__list .speaker__list__menu__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 4rem; }
    @media only screen and (max-width: 47.9375em) {
      .speaker-page__list .speaker__list__menu__filter {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 4.4rem;
        grid-gap: 2rem; } }
    .speaker-page__list .speaker__list__menu__filter .speaker__count__wrap {
      width: 3.4rem;
      height: 3.4rem;
      border: 0.1rem solid #041E41;
      position: relative; }
      .speaker-page__list .speaker__list__menu__filter .speaker__count__wrap__num {
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        width: 3.4rem;
        height: 3.4rem;
        background: #041E41;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center; }
      .speaker-page__list .speaker__list__menu__filter .speaker__count__wrap__title {
        margin-top: 0.2rem;
        margin-left: 4.5rem;
        display: inline-block;
        font-size: 2rem;
        line-height: 2.8rem;
        color: #041E41; }
    .speaker-page__list .speaker__list__menu__filter .filter {
      display: flex;
      align-items: center;
      position: relative; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page__list .speaker__list__menu__filter .filter button.find {
          display: none; } }
    .speaker-page__list .speaker__list__menu__filter button {
      padding: 1rem 3rem 1rem 2.4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      border-radius: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      transition: .3s ease-in; }
      .speaker-page__list .speaker__list__menu__filter button svg {
        margin-left: 1rem;
        transition: .3s ease-in; }
    .speaker-page__list .speaker__list__menu__filter button.find {
      margin-right: 1.6rem;
      color: #ffffff;
      background: #009BDE;
      border: 0.1rem solid #009BDE; }
      .speaker-page__list .speaker__list__menu__filter button.find:hover {
        background: transparent;
        color: #009BDE; }
        .speaker-page__list .speaker__list__menu__filter button.find:hover svg * {
          fill: #009BDE; }
    .speaker-page__list .speaker__list__menu__filter button.clear {
      background: #ffffff;
      color: #565C62;
      border: 0.1rem solid #565C62;
      transition: .3s linear; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page__list .speaker__list__menu__filter button.clear {
          padding: 0.2rem 0;
          border: 0;
          background: transparent; } }
      .speaker-page__list .speaker__list__menu__filter button.clear:hover {
        border-color: #009BDE;
        color: #009BDE; }
        .speaker-page__list .speaker__list__menu__filter button.clear:hover svg * {
          transition: .3s linear;
          fill: #009BDE; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page__list .speaker__list__menu__filter button.clear {
          position: absolute;
          right: 0; } }
    .speaker-page__list .speaker__list__menu__filter button.find-mobile {
      display: none; }
      @media only screen and (max-width: 47.9375em) {
        .speaker-page__list .speaker__list__menu__filter button.find-mobile {
          display: flex;
          padding: 0;
          height: 4.4rem; } }
  .speaker-page__list .speaker__list__view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    color: #041E41; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .speaker-page__list .speaker__list__view {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    @media only screen and (max-width: 80em) {
      .speaker-page__list .speaker__list__view {
        grid-template-columns: repeat(2, 1fr); } }
    @media only screen and (max-width: 47.9375em) {
      .speaker-page__list .speaker__list__view {
        grid-template-columns: 1fr;
        grid-gap: 2rem; } }
    .speaker-page__list .speaker__list__view .speaker__list__item {
      padding: 6rem 2.3rem 2rem;
      border: 0.1rem solid #041E41;
      position: relative;
      display: flex;
      flex-direction: column; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .speaker-page__list .speaker__list__view .speaker__list__item {
          max-width: 36rem;
          width: 100%;
          margin-bottom: 4rem; } }
      .speaker-page__list .speaker__list__view .speaker__list__item__close {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(192, 63, 19, 0.04);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        border: 0.1rem solid #C03F13;
        outline: none;
        cursor: pointer;
        border-right: none;
        border-top: none; }
        .speaker-page__list .speaker__list__view .speaker__list__item__close::before {
          display: none; }
      .speaker-page__list .speaker__list__view .speaker__list__item__image {
        padding: 1rem;
        border: 0.1rem solid #041E41;
        display: flex;
        justify-content: center; }
        .speaker-page__list .speaker__list__view .speaker__list__item__image .speaker__image {
          width: 100%; }
      .speaker-page__list .speaker__list__view .speaker__list__item__title {
        font-family: "Druk Wide Trial", sans-serif;
        font-size: 2.2rem;
        line-height: 2rem;
        margin: 3rem 0 1.6rem;
        color: #041E41; }
        @media only screen and (max-width: 47.9375em) {
          .speaker-page__list .speaker__list__view .speaker__list__item__title {
            margin: 3rem 0 0.8rem; } }
      .speaker-page__list .speaker__list__view .speaker__list__item__text {
        font-family: "Gotham Pro", sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #565C62;
        margin-bottom: 1.6rem;
        min-height: 8.8rem; }
      .speaker-page__list .speaker__list__view .speaker__list__item__details {
        display: flex;
        margin-bottom: 3rem; }
        .speaker-page__list .speaker__list__view .speaker__list__item__details .outline {
          display: flex;
          align-items: center;
          width: 2.4rem;
          height: 2.4rem;
          border: 0.1rem solid #041E41;
          position: relative; }
          .speaker-page__list .speaker__list__view .speaker__list__item__details .outline span {
            margin-top: 0.5rem;
            margin-left: 3rem;
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: #041E41; }
          .speaker-page__list .speaker__list__view .speaker__list__item__details .outline:last-child {
            margin-left: 9rem; }
          .speaker-page__list .speaker__list__view .speaker__list__item__details .outline .total {
            font-size: 1.2rem;
            line-height: 1.8rem;
            width: 2.4rem;
            height: 2.4rem;
            background: #041E41;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0.2rem;
            left: 0.2rem;
            color: #ffffff;
            position: absolute; }
      .speaker-page__list .speaker__list__view .speaker__list__item__btn {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #009BDE;
        border: none;
        border-bottom: 0.1rem solid #009BDE;
        outline: none;
        background: transparent;
        cursor: pointer;
        position: relative;
        padding: 1.2rem;
        align-self: flex-start;
        justify-content: space-between; }
        .speaker-page__list .speaker__list__view .speaker__list__item__btn::before {
          content: '';
          position: absolute;
          max-width: 7.9rem;
          width: 100%;
          top: 0;
          left: 0;
          height: 0.1rem;
          background: #009BDE; }
      .speaker-page__list .speaker__list__view .speaker__list__item .btn-outline-none {
        border: none; }
        .speaker-page__list .speaker__list__view .speaker__list__item .btn-outline-none::before {
          display: none; }

.contact-page .contact {
  padding: 10rem 0;
  margin-top: 9.7rem;
  background: #F8F9FA; }
  @media only screen and (max-width: 47.9375em) {
    .contact-page .contact {
      margin-top: 6.4rem;
      padding: 4rem 0 6rem; } }
  .contact-page .contact .contact__info {
    padding-left: 3rem;
    border-left: 0.1rem solid #041E41; }
    @media only screen and (max-width: 47.9375em) {
      .contact-page .contact .contact__info {
        padding-left: 1.6rem; } }
    .contact-page .contact .contact__info__title {
      margin: 1.2rem 0; }
      @media only screen and (max-width: 47.9375em) {
        .contact-page .contact .contact__info__title {
          margin: 0.8rem 0; } }
    .contact-page .contact .contact__info__text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #565C62; }
      @media only screen and (max-width: 47.9375em) {
        .contact-page .contact .contact__info__text {
          font-size: 1.4rem;
          line-height: 2.2rem; } }
  .contact-page .contact .contact__wrap {
    padding: 4rem 0 10rem;
    display: grid;
    grid-template-columns: minmax(20rem, 60.6rem) minmax(20rem, 51.4rem);
    grid-column-gap: 4rem; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .contact-page .contact .contact__wrap {
        display: flex;
        justify-content: space-between; } }
    @media only screen and (max-width: 80em) {
      .contact-page .contact .contact__wrap {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem; } }
    @media only screen and (max-width: 47.9375em) {
      .contact-page .contact .contact__wrap {
        padding-top: 3rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: column-reverse; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .contact-page .contact .contact__wrap .contact__address {
        width: 50%; } }
    .contact-page .contact .contact__wrap .contact__address .contact__map {
      margin-right: 0;
      max-width: 60.6rem;
      width: 100%;
      height: 50.1rem; }
      @media only screen and (max-width: 47.9375em) {
        .contact-page .contact .contact__wrap .contact__address .contact__map {
          max-width: 100%; } }
    .contact-page .contact .contact__wrap .contact__address .address__title {
      margin: 4rem 0 3rem;
      font-size: 2rem;
      line-height: 2.8rem;
      color: #041E41; }
      @media only screen and (max-width: 80em) {
        .contact-page .contact .contact__wrap .contact__address .address__title {
          margin-bottom: 2rem; } }
    .contact-page .contact .contact__wrap .contact__address .address__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 6rem; }
      @media only screen and (max-width: 80em) {
        .contact-page .contact .contact__wrap .contact__address .address__container {
          grid-template-columns: 1fr; } }
      .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: #565C62; }
        .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap:first-child {
          margin-bottom: 3rem; }
        .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap .address__wrap__title {
          color: #222222;
          font-size: 1.6rem; }
        .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap span.number {
          text-decoration: none;
          color: #565C62; }
        .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap .address__street {
          margin-bottom: 2rem; }
        .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap .link-map {
          display: flex;
          align-items: center;
          margin-top: 1.7rem;
          font-size: 1.6rem;
          line-height: 1.8rem;
          color: #009BDE;
          text-underline-offset: 0.3rem;
          transition: .3s ease-in; }
          .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap .link-map:hover {
            text-decoration: none; }
            .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap .link-map:hover svg {
              margin-left: 1rem; }
          .contact-page .contact .contact__wrap .contact__address .address__container .address__wrap .link-map svg {
            transition: .3s ease-in;
            margin-left: 0.5rem; }
    .contact-page .contact .contact__wrap .contact__address .address__personal {
      margin-top: 6rem; }
      .contact-page .contact .contact__wrap .contact__address .address__personal__title {
        margin-bottom: 1.2rem;
        font-size: 2rem;
        line-height: 2.8rem;
        color: #000000; }
      .contact-page .contact .contact__wrap .contact__address .address__personal__btn {
        padding: 1.3rem;
        max-width: 24rem;
        width: 100%;
        background: #388FCB; }
        .contact-page .contact .contact__wrap .contact__address .address__personal__btn svg {
          transition: .3s ease;
          margin-left: 0.8rem; }
        .contact-page .contact .contact__wrap .contact__address .address__personal__btn:hover svg {
          margin-left: 1.5rem; }
    .contact-page .contact .contact__wrap .contact__form {
      padding: 2.4rem;
      background: #F8F9FA;
      border: 0.1rem solid #565C62;
      max-height: 60rem;
      line-height: 0; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .contact-page .contact .contact__wrap .contact__form {
          margin-left: 4rem;
          width: 50%; } }
      @media only screen and (max-width: 47.9375em) {
        .contact-page .contact .contact__wrap .contact__form {
          padding: 1.6rem 1.2rem;
          justify-content: flex-start;
          margin-bottom: 2.8rem; } }
      .contact-page .contact .contact__wrap .contact__form .form-group {
        margin-bottom: 2rem; }
        @media only screen and (max-width: 47.9375em) {
          .contact-page .contact .contact__wrap .contact__form .form-group {
            margin-bottom: 1.4rem; } }
        .contact-page .contact .contact__wrap .contact__form .form-group .form-group__textarea {
          margin-bottom: 0;
          min-height: 16.2rem; }
        .contact-page .contact .contact__wrap .contact__form .form-group:last-child {
          margin-bottom: 0; }
      .contact-page .contact .contact__wrap .contact__form .btn-group {
        margin-top: 4rem; }
        @media only screen and (max-width: 47.9375em) {
          .contact-page .contact .contact__wrap .contact__form .btn-group {
            margin-top: 3rem; } }
        .contact-page .contact .contact__wrap .contact__form .btn-group .form-group__btn {
          background: #FFBD21;
          display: inline-block;
          width: 100%;
          padding: 1.4rem;
          border: none;
          outline: none; }
          .contact-page .contact .contact__wrap .contact__form .btn-group .form-group__btn:hover {
            background: #FFB300; }

.search-page .search__main {
  margin-top: 10.5rem;
  background: #E5E5E5; }
  @media only screen and (max-width: 47.9375em) {
    .search-page .search__main {
      margin-top: 6.5rem; } }
  .search-page .search__main .search__inner {
    margin: 0 auto;
    max-width: 116rem; }
    @media only screen and (max-width: 80em) {
      .search-page .search__main .search__inner {
        padding: 0 1.6rem; } }
    @media only screen and (max-width: 47.9375em) {
      .search-page .search__main .search__inner {
        padding: 0 1.6rem; } }
    .search-page .search__main .search__inner form.search {
      padding-top: 10rem; }
      @media only screen and (max-width: 47.9375em) {
        .search-page .search__main .search__inner form.search {
          padding-top: 4rem; } }
      .search-page .search__main .search__inner form.search .search__top {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .search-page .search__main .search__inner form.search .search__top .search__input {
          background: rgba(255, 255, 255, 0.8);
          border: 0.1rem solid #122741;
          border-radius: 0.2rem;
          outline: none;
          width: 100%;
          padding: 1.4rem;
          display: block; }
        .search-page .search__main .search__inner form.search .search__top .search__btn {
          padding: 1.2rem;
          max-width: 20rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1.2rem; }
          @media only screen and (max-width: 47.9375em) {
            .search-page .search__main .search__inner form.search .search__top .search__btn {
              width: 4.8rem;
              height: 4.8rem; }
              .search-page .search__main .search__inner form.search .search__top .search__btn span {
                display: none; } }
          .search-page .search__main .search__inner form.search .search__top .search__btn svg {
            margin-left: 0.9rem; }
            @media only screen and (max-width: 47.9375em) {
              .search-page .search__main .search__inner form.search .search__top .search__btn svg {
                margin-left: 0; } }
      .search-page .search__main .search__inner form.search .search__filter {
        padding-bottom: 3rem;
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 3rem;
        border-bottom: 0.1rem solid #DEE2E6; }
        @media only screen and (max-width: 47.9375em) {
          .search-page .search__main .search__inner form.search .search__filter {
            grid-template-columns: 1fr; } }
        .search-page .search__main .search__inner form.search .search__filter .search__box {
          display: flex;
          flex-direction: column; }
          @media only screen and (max-width: 47.9375em) {
            .search-page .search__main .search__inner form.search .search__filter .search__box {
              margin-bottom: 1.4rem; } }
          .search-page .search__main .search__inner form.search .search__filter .search__box label {
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #565C62; }
          .search-page .search__main .search__inner form.search .search__filter .search__box select {
            padding: 1.2rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #565C62;
            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.8);
            border: 0.1rem solid #B6B6B6;
            border-radius: 0.2rem;
            position: relative; }

.search-page .speaker-page__list {
  margin-top: 0;
  background: #E5E5E5; }
  .search-page .speaker-page__list .speaker__list__item__close {
    background: rgba(255, 255, 255, 0.04) !important;
    border-color: #009BDE !important; }
    .search-page .speaker-page__list .speaker__list__item__close svg * {
      fill: #009BDE !important; }

.topic-page .topic-about {
  position: relative;
  margin-top: 10.5rem; }
  .topic-page .topic-about::before {
    z-index: 2;
    position: absolute;
    content: '';
    background: rgba(4, 30, 65, 0.2);
    border-radius: 0.2rem;
    height: 41.3rem;
    width: 50%;
    left: 0;
    display: block;
    top: 15%; }
    @media only screen and (max-width: 80em) {
      .topic-page .topic-about::before {
        width: 70%; } }
    @media only screen and (max-width: 80em) {
      .topic-page .topic-about::before {
        display: none; } }
  .topic-page .topic-about .slide-1 {
    padding-top: 10.5rem;
    background: url("../images/topic-page/topic-bg.jpg") no-repeat 100% 100% !important;
    background-size: cover !important;
    position: relative !important; }
    .topic-page .topic-about .slide-1 .slide-info {
      position: relative; }
      @media only screen and (max-width: 80em) {
        .topic-page .topic-about .slide-1 .slide-info {
          top: 0; } }
      @media only screen and (max-width: 47.9375em) {
        .topic-page .topic-about .slide-1 .slide-info {
          bottom: auto !important;
          top: 20rem; } }
      .topic-page .topic-about .slide-1 .slide-info .about__title {
        font-size: 4.8rem !important;
        line-height: 5.6rem !important; }
      .topic-page .topic-about .slide-1 .slide-info hr {
        background: #DEE2E6 !important;
        height: 0.1rem !important; }
      .topic-page .topic-about .slide-1 .slide-info button {
        padding: 1rem 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFBD21;
        margin-top: 3.2rem;
        cursor: pointer; }
        .topic-page .topic-about .slide-1 .slide-info button svg {
          margin-left: 0.8rem;
          transition: .3s ease; }
        .topic-page .topic-about .slide-1 .slide-info button:hover {
          background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%); }
          .topic-page .topic-about .slide-1 .slide-info button:hover svg {
            margin-left: 1.5rem; }
  .topic-page .topic-about .slide-2 {
    background: url("../images/topic-page/topic-bg.jpg") no-repeat 100% 100% !important;
    background-size: cover; }
    .topic-page .topic-about .slide-2 .about__title {
      max-width: 54.4rem; }
  .topic-page .topic-about .swiper__small {
    bottom: 12.9rem;
    position: absolute;
    color: #ffffff;
    z-index: 100; }
    @media only screen and (max-width: 47.9375em) {
      .topic-page .topic-about .swiper__small {
        background: #041E41;
        width: 100%;
        left: 0;
        padding-bottom: 7.9rem;
        padding-left: 1.6rem;
        padding-top: 2.4rem;
        bottom: 0; } }
    .topic-page .topic-about .swiper__small .swiper__small__title {
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 2rem; }
    .topic-page .topic-about .swiper__small .banner {
      display: flex; }
      .topic-page .topic-about .swiper__small .banner .banner__wrap {
        display: flex;
        flex-direction: column;
        margin-right: 1.6rem; }
        .topic-page .topic-about .swiper__small .banner .banner__wrap span {
          margin-top: 0.8rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #EAEAEA; }
  .topic-page .topic-about .btn__swiper__wrap {
    justify-content: flex-start !important; }
    @media only screen and (max-width: 47.9375em) {
      .topic-page .topic-about .btn__swiper__wrap .swiper-button-prev, .topic-page .topic-about .btn__swiper__wrap .swiper-button-next {
        margin-top: -40rem !important; } }
    @media only screen and (max-width: 47.9375em) {
      .topic-page .topic-about .btn__swiper__wrap .swiper-button-next {
        left: 8rem; } }

.topic-page .topic-speakers .speakers__title {
  color: #009BDE; }

.topic-page .topic-speakers .speakers__text {
  margin-top: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #565C62;
  max-width: 58.5rem; }

.topic-page .topic-subtopics .subtopics__title {
  color: #FFBD21; }

.topic-page .topic-subtopics .swiper-slide span {
  color: #565C62; }

.topic-page .topic-subtopics .btn-wrap {
  display: none; }
