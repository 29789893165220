%text-title {
    font-family: $font-default-title;
    font-weight: 500;
    font-size: 4rem;
    line-height: 4.8rem;
    color: $color-blue;

    @include respond(phone) {
        font-size: 2.8rem;
        line-height: 3.6rem;
    }
}

%text-primary {
    font-family: $font-default;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    color: $color-lightblue;

    @include respond(phone) {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
}
