.footer {
  color: $color-white;

  &__top {
    background-color: #122741;

    .container {
      display: flex;
      justify-content: space-between;
      padding: 2.4rem 0;

      @include respond(phone) {
        padding: 1.6rem;
      }

      .footer__logo {
        cursor: pointer;
      }

      .footer__social, .footer__mobile__social {
        display: flex;
        align-items: center;

        @include respond(phone) {
          display: none;
        }

        &__link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.8rem;
          height: 3.8rem;
          cursor: pointer;
          background-color: #DEE2E6;
          border-radius: 0.2rem;
          transition: .3s ease;
          border: 0.1rem solid #DEE2E6;

          &:nth-child(2) {
            margin: 0 0.8rem;
          }

          &:hover {
            background-color: transparent;
            border: 0.1rem solid $color-lightblue;
            svg * {
              transition: .3s ease;
              fill: $color-lightblue;
            }
          }
        }
      }
    }
  }

  &__center {
    padding: 4rem 0;

    &__inner {
      display: flex;
      justify-content: space-between;

      @include respond(phone) {
        flex-direction: column;
      }

      .footer__contact {
        display: flex;
        flex-direction: column;

        @include respond(phone) {
          border-bottom: 0.1rem solid #565C62;
          padding-bottom: 3.2rem;
          padding-top: 3.2rem;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }

        &__title {
          font-size: 1.6rem;
          line-height: 2.4rem;
          text-transform: uppercase;
        }

        &__link {
          margin-top: 1.2rem;
          text-decoration: none;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #F0EFEF;
          transition: .3s ease-in-out;

          &:hover {
            color: $color-lightblue;
            cursor: pointer;

            svg * {
              transition: .3s ease-in-out;
              fill: $color-lightblue;
            }
          }
        }

        .footer__contact__link__number {
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }

  &__bottom {

    &__inner {
      padding: 3.6rem 0;
      border-top: 0.1rem solid rgba(248, 249, 250, 0.2);
      display: flex;
      justify-content: space-between;

      @include respond(phone) {
        flex-direction: column-reverse;
        align-items: center;
        border-top: none;
      }

      .copy {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #DEE2E6;
        width: 50%;

        @include respond(phone) {
          margin-top: 2rem;
          width: 100%;
          justify-content: center;
        }
      }

      .private {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        @include respond(phone) {
          border-bottom: 0.1rem solid rgba(248, 249, 250, 0.2);
          justify-content: center;
        }

        .private__link {
          padding: 1.2rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $color-lightblue;
          text-decoration: none;
          position: relative;
          transition: .3s ease-in-out;

          @include respond(phone) {
            font-size: 1.4rem;
            line-height: 2.2rem;
            padding: 1.2rem 0.2rem;
          }

          @media (max-width: 37rem) {
            text-align: center;
          }

          &:hover {
            opacity: 0.6;
          }

          &::after {
            content: '';
            position: absolute;
            width: 0.1rem;
            height: 1.8rem;
            background-color: #565C62;
            right: -0.6rem;
            top: 50%;
            margin-top: -0.9rem;
          }

          &:nth-child(2) {
            margin: 0 1.2rem;

            @include respond(phone) {
              margin: 0 1rem;
            }
          }

          &:last-child::after {
            display: none;
          }
        }
      }
    }
  }

  &__mobile__social {
    display: none;
    justify-content: center;

    .footer__social__link {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #DEE2E6;
      border-radius: 0.2rem;
      width: 3.8rem;
      height: 3.8rem;

      &:nth-child(2) {
        margin: 0 0.8rem;
      }
    }

    @include respond(phone) {
      display: flex;
    }
  }
}
