.speaker-page {
  .availability {
    padding: 10rem 0;
    background: url("../images/speaker-page/availability.png") no-repeat 100% 100% #F0EFEF;
    background-size: cover;

    @include respond(phone) {
      padding: 5rem 0;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .availability__title {
        font-family: $font-default-title;
        font-size: 4rem;
        line-height: 4.8rem;
        color: $color-blue;

        @include respond(phone) {
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }

      .availability__form {
        margin-top: 4rem;
        max-width: 50rem;
        width: 100%;

        @include respond(phone) {
          margin-top: 3rem;
        }

        .form-group {
          margin-bottom: 2rem;

          .form-group__textarea {
            min-height: 16.2rem;
            max-width: 50rem;
          }
        }

        .btn-group {
          margin-top: 4rem;

          @include respond(phone) {
            margin-top: 3rem;
          }

          button.btn-orange {
            padding: 1.4rem;
            width: 100%;
            display: inline-block;
            background: $color-yellow;
            border: 0.1rem solid $color-yellow;

            &:hover {
             background: transparent;
              color: $color-yellow;
            }
          }
        }
      }
    }
  }
}

.speaker-page__list {
  padding: 8rem 0;
  margin-top: 9.6rem;
  background: #F8F9FA;

  @include respond(phone) {
    padding: 4rem 0 6rem;
  }

  .speaker__list__info {
    padding-left: 3rem;
    border-left: 0.1rem solid $color-blue;

    &__title {
      margin: 1.2rem 0;

      @include respond(phone) {
        margin: 0.8rem 0;
      }
    }

    @include respond(phone) {
      padding-left: 1.6rem;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #565C62;
      max-width: 55.6rem;
    }
  }

  .speaker__list__menu__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 4rem;

    @include respond(phone) {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 4.4rem;
      grid-gap: 2rem;
    }

    .speaker__count__wrap {
      width: 3.4rem;
      height: 3.4rem;
      border: 0.1rem solid $color-blue;
      position: relative;

      &__num {
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        width: 3.4rem;
        height: 3.4rem;
        background: $color-blue;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__title {
        margin-top: 0.2rem;
        margin-left: 4.5rem;
        display: inline-block;
        font-size: 2rem;
        line-height: 2.8rem;
        color: $color-blue;
      }
    }

    .filter {
      display: flex;
      align-items: center;
      position: relative;

      @include respond(phone) {
        button.find {
          display: none;
        }
      }
    }

    button {
      padding: 1rem 3rem 1rem 2.4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      border-radius: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      transition: .3s ease-in;

      svg {
        margin-left: 1rem;
        transition: .3s ease-in;
      }
    }

    button.find {
      margin-right: 1.6rem;
      color: $color-white;
      background: $color-lightblue;
      border: 0.1rem solid $color-lightblue;

      &:hover {
        background: transparent;
        color: $color-lightblue;

        svg * {
          fill: $color-lightblue;
        }
      }
    }

    button.clear {
      background: $color-white;
      color: #565C62;
      border: 0.1rem solid #565C62;
      transition: .3s linear;

      @include respond(phone) {
        padding: 0.2rem 0;
        border: 0;
        background: transparent;
      }

      &:hover {
        border-color: $color-lightblue;
        color: $color-lightblue;

        svg * {
          transition: .3s linear;
          fill: $color-lightblue;
        }
      }

      @include respond(phone) {
        position: absolute;
        right: 0;
      }
    }

    button.find-mobile {
      display: none;

      @include respond(phone) {
        display: flex;
        padding: 0;
        height: 4.4rem;
      }
    }
  }

  .speaker__list__view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    color: $color-blue;

    //IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include respond(tab-land) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }

    .speaker__list__item {
      padding: 6rem 2.3rem 2rem;
      border: 0.1rem solid $color-blue;
      position: relative;
      display: flex;
      flex-direction: column;

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 36rem;
        width: 100%;
        margin-bottom: 4rem;
      }

      &__close {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(192, 63, 19, 0.04);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        border: 0.1rem solid #C03F13;
        outline: none;
        cursor: pointer;
        border-right: none;
        border-top: none;

        &::before {
          display: none;
        }
      }

      &__image {
        padding: 1rem;
        border: 0.1rem solid $color-blue;
        display: flex;
        justify-content: center;

        .speaker__image {
          width: 100%;
        }
      }

      &__title {
        font-family: $font-default-title;
        font-size: 2.2rem;
        line-height: 2rem;
        margin: 3rem 0 1.6rem;
        color: $color-blue;

        @include respond(phone) {
          margin: 3rem 0 0.8rem;
        }
      }

      &__text {
        font-family: $font-default;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #565C62;
        margin-bottom: 1.6rem;
        min-height: 8.8rem;
      }

      &__details {
        display: flex;
        margin-bottom: 3rem;

        .outline {
          display: flex;
          align-items: center;
          width: 2.4rem;
          height: 2.4rem;
          border: 0.1rem solid $color-blue;
          position: relative;

          span {
            margin-top: 0.5rem;
            margin-left: 3rem;
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: $color-blue;
          }

          &:last-child {
            margin-left: 9rem;
          }

          .total  {
            font-size: 1.2rem;
            line-height: 1.8rem;
            width: 2.4rem;
            height: 2.4rem;
            background: $color-blue;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0.2rem;
            left: 0.2rem;
            color: $color-white;
            position: absolute;
          }
        }
      }

      &__btn {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #009BDE;
        border: none;
        border-bottom: 0.1rem solid $color-lightblue;
        outline: none;
        background: transparent;
        cursor: pointer;
        position: relative;
        padding: 1.2rem;
        align-self: flex-start;
        justify-content: space-between;

        &::before {
          content: '';
          position: absolute;
          max-width: 7.9rem;
          width: 100%;
          top: 0;
          left: 0;
          height: 0.1rem;
          background: $color-lightblue;
        }
      }

      .btn-outline-none {
        border: none;

        &::before {
          display: none;
        }
      }
    }
  }
}
