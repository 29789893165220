.custom-checkbox {
  width: 2rem;
  height: 2rem;
  border: 0.1rem solid #009BDE;
  border-radius: 0.1rem;
  background: transparent;
  vertical-align: sub;
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: url("../images/shape.svg") no-repeat;
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    margin-top: 0.2rem;
    transition: 0.2s ease-in;
  }
}

.checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.checkbox__input:checked + span.custom-checkbox::before {
  transform: translate(-50%, -50%) scale(1);
  border: none;
}

.checkbox__input:checked + span.custom-checkbox {
  background: $color-lightblue;
}
