.cooperation {
  padding: 8rem 0 10rem;
  background-color: $color-white;
  position: relative;

  &-bg {
    position: absolute;
    right: 0;
    top: 3rem;
  }

  &__info {
    padding-left: 3rem;
    border-left: 0.1rem solid $color-blue;
    max-width: 64rem;
    margin-bottom: 6rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    grid-gap: 4rem;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F0EFEF;
      min-height: 12.4rem;
      border-bottom: 0.1rem solid $color-blue;
      border-left: 0.1rem solid $color-blue;
      position: relative;

      &::before, &::after {
        position: absolute;
        content: '';
        height: 0.1rem;
        background-color: $color-blue;
        transition: .3s ease;
      }

      &::before {
        max-width: 8rem;
        width: 100%;
        top: 0;
        left: 0;
      }

      &::after {
        max-height: 8rem;
        width: 0.1rem;
        height: 100%;
        bottom: 0;
        right: 0;
      }

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: 16rem;
        width: 100%;
        margin-bottom: 4rem;
      }
    }

    @include respond(phone) {
      display: none;
    }

    //IE
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  @include respond(phone) {
    padding: 4rem 0 6rem;

    .cooperation-bg {
      display: none;
    }

    .cooperation__info {
      padding-left: 1.6rem;

      .text-title {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }

  &-slider {
    display: none;

    .swiper-wrapper {
      display: flex;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 5.8rem;

        img {
          max-height: 5.9rem;
          height: 100%;
        }
      }
    }

    @include respond(phone) {
      display: flex;
    }
  }
}
