.topic-page {
  .topic-about {
    position: relative;
    margin-top: 10.5rem;

    &::before {
      z-index: 2;
      position: absolute;
      content: '';
      background: rgba(4, 30, 65, 0.2);
      border-radius: 0.2rem;
      height: 41.3rem;
      width: 50%;
      left: 0;
      display: block;
      top: 15%;

      @include respond(tab-land) {
        width: 70%;
      }

      @include respond(tab-land) {
        display: none;
      }
    }

    .slide-1 {
      padding-top: 10.5rem;
      background: url("../images/topic-page/topic-bg.jpg") no-repeat 100% 100% !important;
      background-size: cover !important;
      position: relative !important;

      .slide-info {
        position: relative;

        @include respond(tab-land) {
          top: 0;
        }

        @include respond(phone) {
         bottom: auto !important;
          top: 20rem;
        }

        .about__title {
          font-size: 4.8rem !important;
          line-height: 5.6rem !important;
        }

        hr {
          background: #DEE2E6 !important;
          height: 0.1rem !important;
        }

        button {
          padding: 1rem 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $color-yellow;
          margin-top: 3.2rem;
          cursor: pointer;

          svg {
            margin-left: 0.8rem;
            transition: .3s ease;
          }

          &:hover {
            background: $gradient;

            svg {
              margin-left: 1.5rem;
            }
          }
        }
      }
    }

    .slide-2 {
      background: url("../images/topic-page/topic-bg.jpg") no-repeat 100% 100% !important;
      background-size: cover;
      .about__title {
        max-width: 54.4rem;
      }
    }

    .swiper__small {
      bottom: 12.9rem;
      position: absolute;
      color: $color-white;
      z-index: 100;

      @include respond(phone) {
        background: $color-blue;
        width: 100%;
        left: 0;
        padding-bottom: 7.9rem;
        padding-left: 1.6rem;
        padding-top: 2.4rem;
        bottom: 0;
      }

      .swiper__small__title {
        text-transform: uppercase;
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 2rem;
      }

      .banner {
        display: flex;

        .banner__wrap {
          display: flex;
          flex-direction: column;
          margin-right: 1.6rem;

          span {
            margin-top: 0.8rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #EAEAEA;
          }
        }
      }
    }


    .btn__swiper__wrap {
      justify-content: flex-start !important;

      .swiper-button-prev, .swiper-button-next {
        @include respond(phone) {
          margin-top: -40rem !important;
        }
      }

      .swiper-button-next {
        @include respond(phone) {
          left: 8rem;
        }
      }
    }
  }

  //Topic-speakers
  .topic-speakers {
    .speakers__title {
      color: $color-lightblue;
    }

    .speakers__text {
      margin-top: 1.6rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #565C62;
      max-width: 58.5rem;
    }
  }

  //Topic-speakers
    .topic-subtopics {
    .subtopics__title {
      color: $color-yellow;
    }

      .swiper-slide span {
        color: #565C62;
      }

    .btn-wrap {
      display: none;
    }
  }
}
