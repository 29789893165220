.contact-page {

  .contact {
    padding: 10rem 0;
    margin-top: 9.7rem;
    background: #F8F9FA;

    @include respond(phone) {
      margin-top: 6.4rem;
      padding: 4rem 0 6rem;
    }

    .contact__info {
      padding-left: 3rem;
      border-left: 0.1rem solid $color-blue;

      @include respond(phone) {
        padding-left: 1.6rem;
      }

      &__title {
        margin: 1.2rem 0;

        @include respond(phone) {
          margin: 0.8rem 0;
        }
      }

      &__text {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #565C62;

        @include respond(phone) {
          font-size: 1.4rem;
          line-height: 2.2rem;
        }
      }
    }

    .contact__wrap {
      padding: 4rem 0 10rem;
      display: grid;
      grid-template-columns: minmax(20rem, 60.6rem) minmax(20rem, 51.4rem);
      grid-column-gap: 4rem;

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        justify-content: space-between;
      }

      @include respond(tab-land) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
      }

      @include respond(phone) {
        padding-top: 3rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: column-reverse;
      }

      .contact__address {
        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 50%;
        }

        .contact__map {
          margin-right: 0;
          max-width: 60.6rem;
          width: 100%;
          height: 50.1rem;

          @include respond(phone) {
            max-width: 100%;
          }
        }

        .address__title {
          margin: 4rem 0 3rem;
          font-size: 2rem;
          line-height: 2.8rem;
          color: $color-blue;

          @include respond(tab-land) {
            margin-bottom: 2rem;
          }
        }

        .address__container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 6rem;

          @include respond(tab-land) {
            grid-template-columns: 1fr;
          }

          .address__wrap {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.4rem;
            color: $default-color;

            &:first-child {
              margin-bottom: 3rem;
            }

            .address__wrap__title {
              color: #222222;
              font-size: 1.6rem;
            }

            span.number {
              text-decoration: none;
              color: #565C62;
            }

            .address__street {
              margin-bottom: 2rem;
            }

            .link-map {
              display: flex;
              align-items: center;
              margin-top: 1.7rem;
              font-size: 1.6rem;
              line-height: 1.8rem;
              color: $color-lightblue;
              text-underline-offset: 0.3rem;
              transition: .3s ease-in;

              &:hover {
                text-decoration: none;

                svg {
                  margin-left: 1rem;
                }
              }

              svg {
                transition: .3s ease-in;
                margin-left: 0.5rem;
              }
            }
          }
        }

        .address__personal {
          margin-top: 6rem;

          &__title {
            margin-bottom: 1.2rem;
            font-size: 2rem;
            line-height: 2.8rem;
            color: #000000;
          }

          &__btn {
            padding: 1.3rem;
            max-width: 24rem;
            width: 100%;
            background: #388FCB;

            svg {
              transition: .3s ease;
              margin-left: 0.8rem;
            }

            &:hover svg{
              margin-left: 1.5rem;
            }
          }
        }
      }

      .contact__form {
        padding: 2.4rem;
        background: #F8F9FA;
        border: 0.1rem solid #565C62;
        max-height: 60rem;
        line-height: 0;

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-left: 4rem;
          width: 50%;
        }

        @include respond(phone) {
          padding: 1.6rem 1.2rem;
          justify-content: flex-start;
          margin-bottom: 2.8rem;
        }

        .form-group {
          margin-bottom: 2rem;

          @include respond(phone) {
            margin-bottom: 1.4rem;
          }

          .form-group__textarea {
            margin-bottom: 0;
            min-height: 16.2rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .btn-group {
          margin-top: 4rem;

          @include respond(phone) {
            margin-top: 3rem;
          }

          .form-group__btn {
            background: $color-yellow;
            display: inline-block;
            width: 100%;
            padding: 1.4rem;
            border: none;
            outline: none;

            &:hover {
              background: #FFB300;
            }
          }
        }
      }
    }
  }
}
