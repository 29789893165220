.search-page {

  .search__main {
    margin-top: 10.5rem;
    background: #E5E5E5;

    @include respond(phone) {
      margin-top: 6.5rem;
    }

    .search__inner {
      margin: 0 auto;
      max-width: 116rem;

      @include respond(tab-land) {
        padding: 0 1.6rem;
      }

      @include respond(phone) {
        padding: 0 1.6rem;
      }

      form.search {
        padding-top: 10rem;

        @include respond(phone) {
          padding-top: 4rem;
        }

        .search__top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .search__input {
            background: rgba(255, 255, 255, 0.8);
            border: 0.1rem solid #122741;
            border-radius: 0.2rem;
            outline: none;
            width: 100%;
            padding: 1.4rem;
            display: block;
          }

          .search__btn {
            padding: 1.2rem;
            max-width: 20rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1.2rem;

            @include respond(phone) {
              width: 4.8rem;
              height: 4.8rem;

              span {
                display: none;
              }
            }

            svg {
              margin-left: 0.9rem;

              @include respond(phone) {
                margin-left: 0;
              }
            }
          }
        }

        .search__filter {
          padding-bottom: 3rem;
          margin-top: 2rem;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 3rem;
          border-bottom: 0.1rem solid #DEE2E6;

          @include respond(phone) {
            grid-template-columns: 1fr;
          }

          .search__box {
            display: flex;
            flex-direction: column;

            @include respond(phone) {
              margin-bottom: 1.4rem;
            }

            label {
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: #565C62;
            }

            select {
              padding: 1.2rem;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: #565C62;
              display: flex;
              align-items: center;
              background: rgba(255, 255, 255, 0.8);
              border: 0.1rem solid #B6B6B6;
              border-radius: 0.2rem;
              position: relative;
            }
          }
        }
      }
    }
  }

  .speaker-page__list {
    margin-top: 0;
    background: #E5E5E5;

    .speaker__list__item__close {
      background: rgba(255, 255, 255, 0.04) !important;
      border-color: $color-lightblue !important;

      svg * {
        fill: $color-lightblue !important;
      }
    }
  }
}
