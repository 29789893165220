.about-page {
  background: $color-blue;
  font-family: $font-default;

  //Main
  .main {
    min-height: 92rem;
    overflow-x: hidden;

    @include respond(phone) {
      min-height: calc(100vh - 6.4rem);
      padding-bottom: 2rem;
    }

    &__inner {
      margin-top: 20rem;
      display: grid;
      grid-template-columns: minmax(10rem, 56.8rem) 1fr;
      grid-column-gap: 3.5rem;

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        justify-content: space-between;
      }

      @include respond(tab-land) {
        grid-template-columns: minmax(10rem, 40rem) 1fr;
      }

      @include respond(phone) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 6.5rem;
      }

      .main__info {

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 50%;
        }

        &__title {
          margin-top: 1.2rem;
          font-family: $font-default-title;
          color: $color-white;
          font-size: 6.4rem;
          line-height: 7.2rem;

          @include respond(phone) {
            font-size: 3.2rem;
            line-height: 4rem;
          }
        }

        hr {
          margin: 1.2rem 0 2.4rem;
          height: 0.1rem;
          background: #DEE2E6;

          @include respond(phone) {
            max-width: 33.3rem;
          }
        }

        &__text {
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #DEE2E6;
          margin-bottom: 1.2rem;
        }
      }

      .main__image__wrap {
        position: relative;

        @include respond(phone) {
          display: flex;
          justify-content: center;
          margin-bottom: 1.5rem;
        }

        .main__image {
          width: 100%;
          height: auto;
          display: block;
          top: -10.2rem;
          position: absolute;
          animation: fade 10s linear infinite;

          //IE10+
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: static;
          }

          @include respond(tab-land) {
            max-width: 40rem;
            width: 100%;
            max-height: 40rem;
            height: 100%;
            top: 0;
          }

          @include respond(phone) {
            position: static;
            max-width: 31.4rem;
          }
        }

        @keyframes fade {
          0% { opacity: 1 }
          100% { opacity: 0 }
        }
      }
    }
  }

  //About
  .about {
    padding: 8rem 0;
    background-color: #F0EFEF;
    height: auto;

    @include respond(phone) {
      padding: 4rem 0 6rem;
    }

    &__info {
      max-width: 70rem;
      padding-left: 3rem;
      border-left: 0.1rem solid $color-blue;
      margin-bottom: 6rem;

      @include respond(phone) {
        margin-bottom: 3rem;
      }

      @include respond(phone) {
        padding-left: 1.6rem;
      }

      &__title {
        margin-top: 1.2rem;

        @include respond(phone) {
          margin-top: 0.8rem;
        }
      }

      &__text {
        margin-top: 1.2rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $default-color;

        @include respond(phone) {
          margin-top: 0.8rem;
        }
      }
    }

    &__card__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem;

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      @include respond(tab-land) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
      }

      &__item {
        padding: 2rem;
        background-color: $color-white;
        border: 0.1rem solid $color-lightblue;

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          max-width: 36rem;
          width: 100%;
          margin-bottom: 4rem;
        }

        .number {
          color: $color-lightblue;
          font-family: $font-default-title;
          font-size: 2.4rem;
          line-height: 3.2rem
        }

        .title {
          font-family: $font-default-title;
          margin: 1.2rem 0 0.8rem;
          color: $color-blue;
          font-size: 1.6rem;
          line-height: 2.4rem
        }

        hr {
          background-color: $color-lightblue;
          max-width: 14rem;
          height: 0.1rem;

          //IE10+
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            text-align: left;
          }
        }

        .text {
          margin-top: 0.8rem;
          color: $default-color;
          font-size: 1.4rem;
          line-height: 2.2rem;

          .primary {
            color: $color-lightblue;
          }
        }
      }
    }
  }

  //Leaders
  .leaders {
    background: $color-white;
    padding: 8rem 0;

    @include respond(phone) {
      padding: 4rem 0 5rem;
    }

    &__info {
      padding-left: 3rem;
      border-left: 0.1rem solid $color-blue;
      margin-bottom: 8.5rem;

      @include respond(phone) {
        padding-left: 1.6rem;
        margin-bottom: 3rem;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;

      &__item {
        display: grid;
        grid-template-columns: minmax(20rem, 32.5rem) minmax(20rem, 44.6rem);
        grid-column-gap: 6rem;
        border-bottom: 0.1rem solid #DEE2E6;
        margin-bottom: 6rem;
        padding-bottom: 6rem;

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: flex;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @include respond(phone) {
          display: flex;
          flex-direction: column;
          margin-bottom: 3rem;
          padding-bottom: 3rem;
        }

        &__wrap {
          border: 0.1rem solid $color-blue;

          @include respond(phone) {
            max-width: 42rem;
            width: 100%;
          }

          .leaders__image {
            position: relative;
            top: 1.2rem;
            left: 1.2rem;

            @include respond(big-desktop) {
              height: 100%;
            }

            @include respond(phone) {
              top: 1.5rem;
              left: 0.8rem;
              max-width: 45rem;
              width: 100%;
            }
          }
        }

        &__information {
          padding-top: 1.4rem;

          //IE10+
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-left: 4rem;
          }

          @include respond(phone) {
            padding-top: 2rem;
          }

          &__title {
            font-size: 2.4rem;
            line-height: 3.2rem;
            margin-bottom: 3rem;
            margin-top: 0.8rem;

            @include respond(phone) {
              margin-bottom: 2rem;
            }
          }

          &__contact {
            color: $color-blue;
            display: grid;
            grid-template-columns: minmax(1rem, 3.4rem) 1fr;
            grid-row-gap: 2rem;
            grid-column-gap: 1.6rem;
            align-items: center;

            .icon {
              min-height: 3.4rem;
              border: 0.1rem solid $color-blue;
              position: relative;
              display: block;

              //IE10+
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: flex;
                width: 3.4rem;
                margin-bottom: 1rem;
              }

              &::before {
                content: '';
                background: url("../images/about-page/leaders/phone.png") no-repeat center center $color-blue;
                position: absolute;
                width: 3.4rem;
                height: 3.4rem;
                top: 0.4rem;
                left: 0.4rem;
                display: inline-block;
              }
            }

            .icon-email {
              //IE10+
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin-top: 1rem;
              }
            }

            .icon-email::before {
              background: url("../images/about-page/leaders/email.png") no-repeat center center $color-blue;
            }

            .contact__text {
              padding-top: 0.7rem;
              color: #041E41 !important;
              text-decoration: none !important;
            }
          }

          &__history {
            margin-top: 3rem;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #565C62;

            @include respond(phone) {
              margin-top: 2.4rem;
            }
          }
        }
      }
    }
  }

  //Premiere-speakers
  .premiere-speakers {
    .container {
      padding: 0 1.6rem;
    }

    &__text {
      font-size: 1.6rem ;
      line-height: 2.4rem;
    }

    button.btn-primary {
      background-color: transparent;
      border-bottom: 0.1rem solid $color-yellow;
      color: $color-yellow;
      max-width: 16.4rem;
      position: relative;

      svg * {
        fill: $color-yellow;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 0.1rem;
        max-width: 7.9rem;
        width: 100%;
        background-color: $color-yellow;
      }
    }
  }

  //Team
  .team {
    padding: 8rem 0;
    background-color: $color-white;

    &__info {
      padding-left: 3rem;
      border-left: 0.1rem solid $color-blue;
      margin-bottom: 6rem;

      @include respond(phone) {
        padding-left: 1.6rem;
        margin-bottom: 3rem;
      }
    }

    @include respond(phone) {
      padding: 4rem 0 6rem;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem 5rem;

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      @include respond(tab-land) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond(phone) {
        grid-template-columns: 1fr;
        grid-row-gap: 0;
      }

      &__item {
        padding: 2rem 2rem 2rem 3rem;
        border: 0.1rem solid $color-blue;
        position: relative;
        margin-bottom: 4rem;

        &:last-child {
          margin-bottom: 0;
        }

        &__image {
          @include respond(phone) {
            object-fit: cover;
            max-width: 100%;
          }
        }

        @include respond(phone) {
          margin: 0 0 3rem;
          padding: 1.2rem 1.2rem 1.2rem 3.7rem;
        }

        &__wrap {
          padding: 1.6rem;
          position: absolute;
          background-color: $color-white;
          color: $color-blue;
          bottom: 0;
          left: 0;
          max-width: 82%;
          width: 100%;
          transition: .3s ease-in-out;
          cursor: pointer;

          &__name {
            font-size: 2rem;
            line-height: 2.8rem;
          }

          hr {
            margin: 0.8rem 0 1.7rem;
            background: $color-lightblue;
            height: 0.1rem;
            max-width: 12rem;

            //IE10+
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
             text-align: left;
            }
          }

          &:hover {
            background-color: $color-blue;
            color: $color-white;
          }
        }
      }
    }
  }
}
