*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
    scroll-behavior: smooth;
    overflow-x: hidden;

    @include respond(big-desktop) {
        font-size: 75%;
    }
}

html.block-scroll {
    overflow: hidden;
}

body {
    display: flex;
    flex-direction: column;
    font-family: $font-default;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.6rem;
    color: $color-white;
    background-color: $color-blue;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
    }
}

.container {
    max-width: 116rem;
    width: 100%;
    margin: 0 auto;

    @include respond(tab-land) {
        max-width: 83.4rem;
        padding: 0 2rem;
    }

    @include respond(phone) {
        padding: 0 1.6rem;
    }
}

.text-primary {
    @extend %text-primary;
}

.text-title {
    @extend %text-title;
}

hr {
    border: none;
    outline: none;
    display: block;
    background-color: $color-lightblue;
}

//iPhone and Safari (border-radius)
select,
textarea,
input[type="text"],
input[type="search"],
input[type="button"],
input[type="submit"]{
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
}


