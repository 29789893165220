.speakers {
  background-color: #F0EFEF;
  padding: 8rem 0 10rem;
  font-family: $font-default;

  &__info {
    padding-left: 3rem;
    border-left: 0.1rem solid $color-lightblue;
    margin-bottom: 6rem;

    .text-title {
      margin-top: 0.8rem;
      letter-spacing: 0.1rem;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    margin-bottom: 6rem;

    &__item {
      padding: 3rem 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $color-white;
      border: 0.12rem solid $color-blue;

      &__border-image {
        border: 0.12rem solid $color-blue;
        padding: 1.7rem;

        .speakers__list__item__image {
          width: 100%;
          border: 0.1rem solid #DEE2E6;
        }
      }

      &__name {
        margin-top: 2.7rem;
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 2.8rem;
        color: $color-blue;
      }

      hr {
        width: 12rem;
        height: 0.1rem;
        background-color: $color-lightblue;
      }

      &__text {
        margin-top: 1.6rem;
        text-align: center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #565C62;
        letter-spacing: 0.01rem;

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          min-height: 27rem;
          text-align: center;
        }
      }

      &:hover {
        transition: .3s all;
        background-color: $color-blue;
        cursor: pointer;

        .speakers__list__item__border-image {
          border-color: $color-white;

          .speakers__list__item__image {
            border: 0.1rem solid #DEE2E6;
          }
        }

        .speakers__list__item__name {
          color: $color-white;
        }

        .speakers__list__item__text {
          color: #DEE2E6;
        }
      }

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        text-align: center;
        width: 36rem;
        margin-bottom: 4rem;
      }
    }

    //IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @include respond(tab-land) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .speakers-link {
    padding: 1.2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $color-lightblue;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    transition: .3s all;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4.9rem;
      height: 0.1rem;
      background-color: $color-lightblue;
      transition: .3s all;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.1rem;
      background-color: $color-lightblue;
      transition: .3s all;
    }

    svg {
      transition: .3s all;
      margin-left: 1rem;
    }

    svg * {
      fill: $color-lightblue;
    }

    &:hover::after {
      background-color: #32dbb2;
    }

    &:hover svg {
      margin-top: 1rem;
      stroke: #32dbb2;
      fill: #32dbb2;
    }

    &:hover {
     color: #32dbb2;
    }

    &:hover::before {
      width: 100%;
      transition: .3s all;
      background-color: #32dbb2;
    }
  }

  @include respond(phone) {
    padding: 4rem 0 6rem;

    .speakers__info {
      padding-left: 1.6rem;
      border-left-color: $color-blue;

      .speakers__title {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
      .text-title {
        font-size: 3.2rem;
        line-height: 4rem;
      }
    }

    .speakers__list {
      grid-gap: 3rem;
    }
  }
}

