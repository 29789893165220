.reviews {
  padding: 8rem 0 10rem;
  background: url("../images/main-page/reviews/reviews-bg.jpg") no-repeat 100% 100%;
  background-size: cover;

  @include respond(phone) {
    padding: 4rem 0 6rem;
  }

  &__info {
    max-width: 60rem;
    padding-left: 3rem;
    border-left: 0.1rem solid $color-white;

    @include respond(phone) {
      padding-left: 1.6rem;
    }

    .text-title {
      color: $color-white;

      @include respond(phone) {
        font-size: 3.2rem;
        line-height: 4rem;
      }
    }
  }

  .reviews__swiper__container {
    overflow: hidden;
    position: relative;

    @include respond(phone) {
      padding-left: 1.6rem;
      padding-right:   1.6rem;
    }

    .reviews__swiper__wrapper {
      margin: 0;

      //IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        justify-content: space-between;
        margin: 0 1.6rem;
      }

        .reviews__swiper__slide {
          padding: 4rem;
          color: $color-white;
          background-color: #122741;
          border: 0.1rem solid rgba(255, 255, 255, 0.2);
          border-radius: 0.2rem;

          //IE10+
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            max-width: 40rem;
            width: 100%;
          }

          @include respond(phone) {
            max-width: 100%;
            box-sizing: border-box;
            padding: 3rem 2rem;
          }

          .comments__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .comments {
              text-align: center;
              font-size: 1.4rem;
              line-height: 2.2rem;
              color: #F0EFEF;
              font-style: italic;

              //IE10+
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: block;
                max-width: 28rem;
              }
            }

            .author {
              font-weight: 700;
              margin-top: 1.5rem;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: $color-white;
            }

            hr {
              margin: 1rem auto;
              background-color: $color-lightblue;
              max-width: 9.1rem;
              width: 100%;
              height: 0.1rem;
            }

            .subtitle {
              line-height: 2.2rem;
              color: #DEE2E6;
              font-size: 1.4rem;
            }
          }
        }
      }

    .button-wrap {
      margin: 2rem auto;
      display: flex;
      outline: none;
      border: none;

      .swiper-button-prev,
      .swiper-button-next {
        position: relative;
        background: transparent;
        border: none;
        outline: none;
        top: 0;
        margin: 0;

        //IE10+
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
         display: none;
        }

        &::after {
          display: none;
        }
      }

      .swiper-button-prev {
        margin-right: 4.2rem;
      }

      .swiper-button-next {
        @include respond(phone) {
          margin-left: 2.8rem;

          svg * {
            fill: $color-white;
          }
        }
      }

      .swiper-pagination {
        display: block;
      }

      .swiper-pagination-fraction {
        font-size: 2rem;
        display: block;
        color: $color-white;
      }
    }

    .swiper-pagination {
      margin-top: 10rem;
      position: static;
      display: block;

      @include respond(phone) {
        position: absolute;
        top: -7rem;
        left: 6.5rem;
        font-family: $font-default-title;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        height: 2.4rem;
        width: 6rem;

        .swiper-pagination-current {
          color: $color-lightblue;
        }
      }
    }

    .swiper-pagination-bullet {
      height: 0.2rem;
      background-color: $color-white;
      width: 13.1rem;
      border-radius: 0;
    }
  }}
