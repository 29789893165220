.header {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: $color-white;
    z-index: 1000;
    max-height: 9.6rem;

    &.dark {
        background-color: $color-blue;
        z-index: 100;

        & + .about {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                bottom: 0;
                top: 0;
                left: 0;
                background: rgba(18, 39, 65, 0.56);
                z-index: 5;
            }
        }
    }

    .container {
        padding: 0;
    }

    &__inner {
        .desktop {
            display: flex;
            align-items: center;
            padding: 2.8rem 0;
            justify-content: space-between;

            @include respond(tab-land) {
                padding: 2.5rem 0;
            }

            @include respond(phone) {
                padding: 2.9rem 0;
                display: none;
            }

            .header__logo {
                line-height: 0;
            }
        }

        .mobile {
            display: none;
            background-color: #122741;
            height: 100%;

            &__top {
                padding: 1.7rem 1.6rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $color-blue;

                .logo {
                    max-width: 12.6rem;
                    max-height: 3rem;
                }

                .hamburger__mobile {
                    width: 1.6rem;
                    height: 1.6rem;
                    background-color: transparent;
                    border: none;
                    outline: none;

                    svg.close {
                        display: none;
                    }

                    &.active {
                        svg.active {
                            display: none;
                        }
                        svg.close {
                            display: flex;
                        }
                    }
                }
            }

            .search__mobile,
            .search__results,
            .menu__dropdown__mobile {
                display: none;
            }

            .search__results {
                display: none;

                &__title {
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                }
            }

            @include respond(phone) {
                display: flex;
                flex-direction: column;
            }

            &.active {
                height: 100vh;
                overflow-y: scroll;

                .search__mobile {
                    display: flex;
                    flex-direction: column;
                    margin: 4rem 1.6rem 0;
                    background-color: $color-blue;
                    color: $color-white;

                    .search__wrap {
                        padding: 0 1.2rem;
                        display: flex;
                        align-items: center;
                    }

                    .search__mobile__input {
                        background-color: transparent;
                        padding: 1.2rem;
                        border: none;
                        outline: none;
                        color: $color-white;
                        width: 100%;
                    }
                }

                .search__results {
                    display: flex;
                    flex-direction: column;
                    padding: 1.2rem 1.2rem 1.7rem;
                    margin: 0.4rem 1.6rem 0;
                    background-color: $color-blue;
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;

                    &__label {
                        margin-top: 1.2rem;
                        display: flex;
                        align-items: center;
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        color: #B6B6B6;

                        span.search__results__text {
                            margin-left: 1rem;
                        }
                    }

                    &.active {
                        position: static;
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .menu__dropdown__mobile {
                    margin: 4rem 1.6rem 0;
                    display: flex;
                    flex-direction: column;
                    transition: .3s linear;

                    &__wrap {
                        display: flex;
                        flex-direction: column;
                        transition: 1s ease-in-out;
                        margin-bottom: 5rem;
                        position: relative;

                        .menu__dropdown__mobile__title {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            svg {
                                transition: .1s linear;
                            }

                            &:last-child {
                                background-color: #122741;
                            }
                        }

                        .login {
                            display: inline-block;
                            text-decoration: underline;
                            text-underline-offset: 0.3rem;

                            &::after {
                                display: none;
                            }
                        }

                        .menu__dropdown__mobile__link {
                            display: none;
                            text-decoration: none;
                            color: $color-white
                        }
                    }

                    .menu__dropdown__mobile__title.active {
                        svg {
                            transform: rotate(90deg);
                            right: 0.01rem;
                            top: 0.5rem;
                        }
                    }

                    .menu__dropdown__mobile__title.active ~ .menu__dropdown__mobile__link {
                        display: inline-block;
                        margin-top: 2rem;

                        &:hover {
                            color: $color-lightblue;
                        }
                    }
                }
            }
        }
    }

    &__navigation {
        display: flex;
        margin-left: auto;
        margin-right: 3.2rem;

        @include respond(phone) {
            display: none;
        }

        &__item {
            display: flex;
            color: $color-white;
            margin-right: 7.5rem;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                .header__navigation__item__link {
                    background-image: $gradient;
                    -webkit-background-clip: text;
                    letter-spacing: 0.1rem;
                    color: $gradient;

                    &:hover {
                        color: $color-lightblue;
                    }

                    &:first-child {
                        display: flex;
                        align-items: center;

                        span:last-child {
                            margin-left: 0.8rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 2.4rem;
                            width: 2.4rem;
                            background: rgba(255, 255, 255, 0.08);
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 2.2rem;
                            color: #DEE2E6;
                            border-radius: 50%;
                        }
                    }
                }
            }

            &__link {
                font-size: 1.4rem;
                font-family: $font-default;
                line-height: 2.1rem;
                font-weight: 400;
                text-decoration: none;
                transition: all 0.3s ease;
                color: $color-white;

                &:hover {
                    color: $color-lightblue;

                    svg * {
                        fill: $color-lightblue;
                    }
                }

                &.select {
                    svg {
                        left: 0.4rem;
                        top: -0.2rem;
                        position: relative;
                    }
                }

                &.active {
                    color: $color-lightblue;

                    svg * {
                        fill: $color-lightblue;
                    }

                    svg {
                        transition: .3s ease;
                        transform: rotateX(180deg);
                    }
                }

                //IE10+
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    &:first-child {
                        background: transparent !important;
                    }
                }
            }

            .select__list {
                display: flex;
                transition: .3s linear;
                justify-content: space-between;
                padding: 4rem 7rem;
                position: absolute;
                width: 73.9rem;
                left: -40rem;
                background-color: $color-blue;
                top: -100rem;

                &__column {
                    max-width: 12rem;
                    display: flex;
                    flex-direction: column;

                    .select__list__link {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        color: #F0EFEF;
                        margin-bottom: 1.6rem;
                        &:hover {
                            cursor: pointer;
                            color: $color-lightblue;
                            transition: .2s ease-in-out;
                            text-decoration: underline;
                            text-underline-offset: 0.3rem;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &.active {
                    top: 5.8rem;

                    @include respond(big-desktop) {
                        top: 5.5rem;
                    }

                    @include respond(tab-land) {
                        top: 5.5rem;
                    }
                }
            }
        }

        &.disable {
            display: none;
        }
    }

    .search-active {
        margin-left: 9.8rem;
        width: 100%;
        display: none;
        align-items: center;
        position: relative;
        opacity: 0;
        transition: 3s cubic-bezier(0.39, 1.52, 0.46, 0.92);
        color: $color-white;

        &::before {
            position: absolute;
            content: '';
            background: url("../images/search.png") no-repeat center center;
            width: 1.8rem;
            height: 1.8rem;
            left: 1.5rem;
            top: 50%;
            margin-top: -0.9rem;
        }

        @media (max-width: 128rem) {
            margin-left: 0;
            width: 50%
        }

        &-input {
            background-color: rgba(255, 255, 255, 0.08);
            border-radius: 0.2rem;
            color: #B6B6B6;
            max-width: 54.8rem;
            width: 100%;
            padding: 1.2rem;
            border: none;
            outline: none;
            text-indent: 3.8rem;

            &::placeholder {
                color: #B6B6B6;
            }
        }

        &-button {
            margin-left: 1.2rem;
            padding: 0.9rem;
            max-width: 16rem;
            width: 100%;
        }

        .popular-searches {
            position: absolute;
            top: 6rem;
            padding: 4rem 6rem;
            background-color: $color-blue;
            color: $color-white;

            //IE10+
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                width: 100%;
                left: 0;
            }

            @include respond(tab-land) {
                padding: 4rem 1rem;
            }

            &-title {
                font-family: $font-default-title;
                font-weight: 500;
                font-size: 2rem;
                line-height: 2.8rem;
                text-align: center;
            }

            hr {
                max-width: 26.5rem;
                width: 100%;
                margin: 1.2rem auto;
                height: 0.1rem;
            }

            &-text {
                font-size: 1.6rem;
                line-height: 2.4rem;
                text-align: center;
                color: #DEE2E6;
            }

            &__list {
                margin-top: 4rem;
                display: grid;
                grid-template-columns: repeat(4, minmax(5rem, 12rem));
                grid-column-gap: 4rem;

                //IE10+
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                @include respond(tab-land) {
                    grid-column-gap: 2rem;
                }

                .popular-searches__column {
                    display: flex;
                    flex-direction: column;

                    .popular-searches__list__link {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        transition: .3s ease;
                        text-decoration: none;
                        cursor: pointer;
                        color: $color-white;
                        text-underline-offset: 0.3rem;
                        margin-bottom: 1.6rem;

                        &:hover {
                            color: $color-lightblue;
                            text-decoration: underline;

                        }
                    }
                }
            }
        }

        &.active {
            display: flex;
            opacity: 1;

            @include respond(tab-land) {
                max-width: 50rem;
                width: 100%;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;

        @include respond(phone) {
            position: absolute;
            right: 2.4rem;
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3.4rem;
            height: 3.4rem;
            background-color: rgba(255, 255, 255, 0.08);
            border-radius: 0.2rem;
            border: none;
            outline: none;
            cursor: pointer;
            position: relative;

            &:hover {
                transition: .3s ease-in-out;
                background: $color-lightblue;
            }

            @include respond(phone) {
                background-color: transparent;
            }
        }

        .header__menu__btn.search {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                background-color: rgba(255, 255, 255, 0.29);
                border-radius: 0.1rem;
                width: 0.1rem;
                height: 2.4rem;
                right: -1.25rem;
                top: 50%;
                margin-top: -1.2rem;

                @include respond(phone) {
                    display: none;
                }
            }

            @include respond(phone) {
                display: none;
            }
        }

        .hamburger {
            margin-left: 2.5rem;

            @include respond(phone) {
                margin-left: 0;
            }

            svg.close {
                display: none;
            }

            &.active {
                svg.active {
                    display: none;
                }
                svg.close {
                    display: flex;
                }

                &:hover {
                    background: #F0EFEF;
                    svg.close * {
                        fill: #009BDE;
                    }
                }
            }
        }
    }

    .menu__dropdown {
        transition: .5s linear;
        display: flex;
        flex-direction: column;
        background-color: #122741;
        color: $color-white;
        position: absolute;
        max-width: 78rem;
        width: 100%;
        height: calc(100vh - 9rem);
        padding: 4rem 6rem;
        right: -100rem;
        visibility: hidden;
        top: 9.6rem;
        z-index: 100;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @include respond(big-desktop) {
            top: 9rem;
        }

        @include respond(tab-land) {
            top: 9rem;
        }

        @include respond(phone) {
            display: none !important;
        }

        &__title {
            margin-bottom: 3.7rem;
            margin-top: 6rem;
            font-size: 2rem;
            line-height: 2.8rem;

            &:first-child {
                margin-top: 0;
            }
        }

        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, minmax(8rem, 16rem));
            grid-column-gap: 6rem;

            &.company {
                display: flex;
                flex-direction: column;
            }

            &.address {
                display: flex;
                flex-direction: column;

                .number {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    text-decoration: none;
                    color: #DEE2E6;

                    &:hover {
                        color: $color-lightblue;

                        svg * {
                            fill: $color-lightblue;
                        }
                    }

                    svg {
                        margin-right: 1.2rem;
                    }
                }
            }

            .menu__dropdown__link {
                font-size: 1.6rem;
                line-height: 2.4rem;
                color: #F0EFEF;
                margin-bottom: 1.6rem;
                transition: .3s ease;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: $color-lightblue;
                }
            }
        }

        &__wrap:nth-child(3) {
            display: flex;
            flex-direction: column;
        }

        &.active {
            visibility: visible;
            right: 0;
        }
    }

    .popup-background {
        position: fixed;
        top: 9.5rem;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: rgba(18, 39, 65, 0.56);
        height: calc(100vh - 9rem);
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}
